.contributor-header {
  // font-family: Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif;
  background-color: #12232e;
  h3 {
    color: #eefbfb;
  }
}

.contributor-sub-header {
  border-bottom: 1px solid gray;
  .preview-test {
    background-color: #203647;
    font-size: 12px;
    color: #fff;
    margin-left: 15px;
  }
}
