@import "./_variable";

.custom-hover-button {
  background-color: #ffffff !important;
  color: #673de6 !important;
  border: 1px solid #673de6 !important;
  &:hover {
    color: #ffffff !important;
    background-color: #673de6 !important;
  }
}

.no-hover-button{
  background-color: #ffffff !important;
  border: none !important;
  color: #673de6 !important;
  &:hover{
    background-color: rgb(233, 235, 236) !important;
  }
}

.bg-673de6{
  background-color: #673de6;
  color : #ffffff ;
  &:hover{
    color:  #673de6;
    background-color:#ffffff;
  }
}

.bg-color-673de6{
  background-color: #ffffff ;
  color:#673de6;
  &:hover{
    background-color: #673de6;
    color : #ffffff ;
  }
}

//  CardCover File Code 

// font-size
.fs-12{
  font-size: 12px !important;
}
.fs-16{
  font-size: 16px !important;
}
.fs-14{
  font-size: 14px !important;
}
.fs-18{
  font-size: 18px !important;
}
.fs-20{
  font-size: 20px !important;
}
.fs-24{
  font-size: 24px !important;
}
.fs-28{
  font-size: 28px !important;
}
.fs-32{
  font-size: 32px;
}
.fs-36{
  font-size: 36px;
}
.fs-40{
  font-size: 40px !important;
}
.fs-64{
  font-size: 64px !important;
}



//  font weight 

.fw-900{
  font-weight: 900;
}
.fw-800{
  font-weight: 800;
}
.fw-700{
  font-weight: 700;
}
.fw-600{
  font-weight: 600;
}
.fw-500{
  font-weight: 500;
}


//  color 

.color-7848f4{
  color:#7848f4;
}
.color-voilet{
  color:$light-voilet;
}


//custom CardCover scss 

.custom-cover-card{
  border: 0 !important;
  border-radius: 5px !important;
}