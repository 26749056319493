.home {
  justify-content: space-between;
  .card-shadow {
    border: none;
    box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
    height: auto;
    border-radius: 5px;
  }
  h5 {
    font-size: 13px;
    color: #30006d;
    font-weight: 600;
  }
  .card-tag {
    border-radius: 5px;
    p {
      font-size: 12px;
    }
  }
  .baseline {
    justify-content: baseline;
    align-items: baseline;
  }
}
.contributor-section {
  justify-content: space-between;
  .contributor-btn {
    border: solid 4px #30006d;
    height: 40px;
    width: 200px;
    border-radius: 6px;
    h6 {
      font-weight: 600;
    }
  }
}

.activities {
  text-align: center;
}

@media (max-width: 767px) {
  .home {
    h2 {
      font-size: 24px;
    }
  }
  main {
    justify-content: space-between;
  }

  .left {
    order: 2;
  }

  .right {
    order: 1;
  }
}
