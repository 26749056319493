@import './_variable';

.academy-hero {
  background: #fff;
  justify-content: left;
  align-items: center;
  .hero-head-text {
    font-family: "Roboto";
    font-size: 30px;
  }
  .hero-heading {
    width: 70%;
    font-size: 60px;
  }
  span {
    font-size: 16px;
  }
}

.about-academy {
  background-color: $section-bg;

  .sub-head-text {
    font-size: 20px;
    font-family: "Roboto";
  }
  .heading {
    font-size: 56px;
  }
  p {
    width: 80%;
  }
}

.academy-offer-section {
  h1 {
    font-size: 56px;
    margin-bottom: 50px;
  }
  .offer-card-block {
    h1 {
      font-size: 42px;
      margin-bottom: 50px;
    }
    .offer-card {
      text-align: center;
      border-radius: 20px;
      padding: 20px;
      h6 {
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      span {
        font-size: 18px;
      }
      .round-btn {
        padding: 10px;
        background-color: #fff;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        background: #9672ff;
        color: #fff !important;
        h6 {
          color: #fff !important;
        }
        .round-btn {
          color: #000;
        }
      }
    }
  }
}

.learn-practice-section {
  background-color: $section-bg;
  .learn-card-row {
    padding: 100px 0;
    .col-md-6 {
      &:nth-child(2) {
        position: relative;
        bottom: 50px;
      }
    }
    .col-md-6 {
      &:nth-child(4) {
        position: relative;
        bottom: 50px;
      }
    }
  }
  .learn-card {
    text-align: center;
    border-radius: 20px;
    padding: 20px;
    h6 {
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    span {
      font-size: 18px;
    }
  }
  .learn-text-block {
    padding-left: 100px;
    .heading {
      font-size: 56px;
      width: 80%;
      margin-bottom: 30px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin: 20px 0 5px 25px;
        &::before {
          content: "\f00c";
          font-family: "Font Awesome 5 Pro";
          margin-top: 2px;
          margin-left: -25px;
          display: table;
          float: left;
          color: $light-voilet;
          font-weight: 500;
        }
      }
    }
  }
}

.develop-skill-section {
  h1 {
    font-size: 58px;
    text-align: center;
  }
  p {
    font-size: 18px;
    text-align: center;
  }

  .skill-card {
    border-radius: 20px;
    .icon-block {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.training-workshop-section {
  padding: 200px 0;
  .workshop-card {
    border-radius: 20px;
  }
}

.our-learner-section {
}

.academy-team {
  background: $section-bg;
  .academy-mentor-card {
    .academy-mentor-card-text-section {
      width: 60% !important;
    }
  }
}

.academy-contribution-block {
  h1 {
    font-size: 56px;
  }
  p {
    font-size: 18px;
    color: $paratext;
  }
}

// Workshop-----------------

.workshop-hero-section {
  background-color: #f9f9fd;
  min-height: 500px;
}

.steps-learning-section {
  // background-color: #676767;
  color: #000;
  min-height: 500px;
  h1 {
    font-size: 50px;
  }
  p {
    font-size: 32px;
  }

  .step-card {
    h6 {
      margin-top: 20px;
    }
    span {
      color: $content-color;
      font-size: 14px;
    }
  }
}

.popular-workshop-section {
  .heading {
    font-size: 45px;
  }
}

.workshop-filter-bar {
  // background-color: $light-voilet;
  // border-radius: 10px;
  // display: flex;
  color: #000 !important;
  .filter-badge {
    border: 1px solid #000;
    background-color: #676767;
    color: #000;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
  }
}

.workshop-page-hero {
  min-height: 500px;
  align-items: center;
  h1 {
    font-size: 50px;
  }
  p {
    font-size: 16px;
    margin-top: 30px;
  }
}

.workshop-details-section {
  .detail-block {
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    padding: 20px;
    align-items: center;
    display: flex;
    span {
      font-size: 16px;
      margin-left: 10px;
    }
  }
  .speaker-block {
    background-color: #000;
    border-radius: 5px;
  }
  .participants-images {
  }
}

.about-workshop {
  background-color: #f9f9fd;
  color: #000;
  min-height: 500px;
  align-items: center;
  h1 {
    font-size: 45px;
  }
  p {
    font-size: 18px;
  }
}

.what-you-learn-workshop {
  .learn-card {
    border: 1px solid #676767;
    border-radius: 7px;
  }
}
