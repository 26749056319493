$base-font-color: #726f77;
$heading-text: #353833;
$light-voilet: #7968ce;
$dark-base: #30006d;
$orange: #ff5f15;
$dark-more-bg-color: #121212;
$heading-text: #353833;
$base-font-color: #726f77;
$light_bg: #f9fbfc;

$light-voilet: #7968ce;

$light-grey: #cdd0cb;
$dark-grey: #a6a9b6;
$dark-yellow: #ffb830;
$dark-red: #ff2442;
$dark-blue: #1d91fd;
$dark-green: #04a93b;
$dark-pink: #ec0068;
$dark-theme: #151931;
$light-green: #d6ffce;
$light-red: #f8d2d6;
$light-blue: #d8eff0;
$light-yellow: #fbffc5;
$medium-yellow: #f5f852;
$medium-green: #91fa70;
$medium-red: #fb7367;
// Pastel Colors
$pastel-green: #a4dead;
$pastel-orange: #ffd0a1;
$pastel-blue: #c3e9f8;
$pastel-pink: #f8cbee;
$pastel-purple: #cac3f7;
$pastel-red: #faa0a0;

:root {
  --devsite-headline-font-family: Google Sans, Noto Sans, Noto Sans JP,
    Noto Sans KR, Noto Naskh Arabic, Noto Sans Thai, Noto Sans Hebrew,
    Noto Sans Bengali, sans-serif;
  --devsite-heading-color: #202124;
  --devsite-display-font: 400 44px/52px var(--devsite-headline-font-family);
  --devsite-h1-font: 400 32px/40px var(--devsite-headline-font-family);
  --devsite-h1-letter-spacing: 0;
  --devsite-h1-margin: 48px 0 24px;
  --devsite-h2-border: 0;
  --devsite-h2-font: 400 24px/32px var(--devsite-headline-font-family);
  --devsite-h2-letter-spacing: 0;
  --devsite-h2-margin: 48px 0 24px;
  --devsite-h2-padding: 0;
  --devsite-h3-font: 400 20px/28px var(--devsite-headline-font-family);
  --devsite-table-heading-font: 500 14px/20px var(--devsite-primary-font-family);
  --devsite-wordmark-font: 400 22px/32px var(--devsite-headline-font-family);
  --devsite-button-background-hover: #e8f0fe;
  --devsite-button-background-active: #e8f0fe;
  --devsite-button-border: 1px solid #dadce0;
  --devsite-button-border-hover: 1px solid #d2e3fc;
  --devsite-button-border-active: 1px solid #e8f0fe;
  --devsite-button-border-radius: 4px;
  --devsite-button-box-shadow: none;
  --devsite-button-box-shadow-active: 0 1px 2px 0
      var(--devsite-elevation-key-shadow-color),
    0 2px 6px 2px var(--devsite-elevation-ambient-shadow-color);
  --devsite-button-font: 500 14px/36px var(--devsite-headline-font-family);
  --devsite-button-line-height: 34px;
  --devsite-button-padding: 0 24px;
  --devsite-button-text-transform: none;
  --devsite-background-button-color: #d2e3fc;
  --devsite-background-button-color-hover: #d2e3fc;
  --devsite-button-disabled-background: transparent;
  --devsite-button-disabled-border: 1px solid #f8f9fa;
  --devsite-button-disabled-color: #9aa0a6;
  --devsite-button-disabled-line-height: 34px;
  --devsite-button-primary-background: #1a73e8;
  --devsite-button-primary-background-hover: #1765cc;
  --devsite-button-primary-background-active: #185abc;
  --devsite-button-primary-box-shadow-hover: 0 1px 2px 0
      var(--devsite-elevation-key-shadow-color),
    0 1px 3px 1px var(--devsite-elevation-ambient-shadow-color);
  --devsite-background-button-primary-background-hover: #e8f0fe;
  --devsite-background-button-primary-color: #d2e3fc;
  --devsite-background-button-primary-color-hover: #d2e3fc;
  --devsite-button-white-background-hover: #e8f0fe;
  --devsite-button-white-background-active: #e8f0fe;
  --devsite-button-white-box-shadow-active: 0 1px 2px 0
      var(--devsite-elevation-key-shadow-color),
    0 1px 3px 1px var(--devsite-elevation-ambient-shadow-color);
  --devsite-button-raised-background-hover: #e8f0fe;
  --devsite-button-raised-background-active: #e8f0fe;
  --devsite-button-with-icon-padding: 0 15px;
  --devsite-button-material-icon-margin-x: -12px;
  --devsite-button-white-material-icon-margin-x: 0;
  --devsite-card-border: var(--devsite-primary-border);
  --devsite-card-border-radius: 8px;
  --devsite-card-box-shadow: none;
}

:root {
  --devsite-code-font-family: Roboto Mono, monospace;
  --devsite-primary-font-family: Roboto, Noto Sans, Noto Sans JP, Noto Sans KR,
    Noto Naskh Arabic, Noto Sans Thai, Noto Sans Hebrew, Noto Sans Bengali,
    sans-serif;
  --devsite-h1-margin: 40px 0 20px;
  --devsite-h2-margin: 40px 0 20px;
  --devsite-h3-margin: 32px 0 16px;
  --devsite-h4-font: 500 16px/24px var(--devsite-primary-font-family);
  --devsite-h5-font: 700 14px/24px var(--devsite-primary-font-family);
  --devsite-h6-font: 500 14px/24px var(--devsite-primary-font-family);
  --devsite-background-0: #e8eaed;
  --devsite-background-1: #fff;
  --devsite-background-2: #f8f9fa;
  --devsite-background-3: #f1f3f4;
  --devsite-background-4: #e8eaed;
  --devsite-background-5: #dadce0;
  --devsite-primary-text-color: #202124;
  --devsite-primary-text-rgba: rgba(0, 0, 0, 0.87);
  --devsite-secondary-text-color: #5f6368;
  --devsite-secondary-text-rgba: rgba(0, 0, 0, 0.65);
  --devsite-tertiary-text-color: #bdc1c6;
  --devsite-tertiary-text-rgba: rgba(0, 0, 0, 0.26);
  --devsite-inverted-text-color: #fff;
  --devsite-inverted-text-rgba: hsla(0, 0%, 100%, 0.7);
  --devsite-primary-border: 1px solid #dadce0;
  --devsite-secondary-border: 1px solid #e8eaed;
  --devsite-elevation-key-shadow-color: rgba(60, 64, 67, 0.3);
  --devsite-elevation-ambient-shadow-color: rgba(60, 64, 67, 0.15);
  --devsite-elevation-inset-shadow-color: rgba(154, 160, 166, 0.5);
  --tenant-background-1: #fff;
  --tenant-background-2: #f8f9fa;
  --tenant-background-3: #f1f3f4;
  --tenant-primary-text-color: #202124;
  --tenant-secondary-text-color: #5f6368;
  --tenant-inverted-text-color: #fff;
  --tenant-primary-border: 1px solid #dadce0;
  --tenant-secondary-border: 1px solid #e8eaed;
  --devsite-link-background: #d2e3fc;
  --devsite-link-background-active: #e8f0fe;
  --devsite-link-color: #1a73e8;
  --devsite-contrast-link-color: #185abc;
  --devsite-button-background-hover: #e4eefc;
  --devsite-button-background-active: #c8ddf9;
  --devsite-button-color: #1a73e8;
  --devsite-button-primary-color: #fff;
  --devsite-caution-notice-background: #feefe3;
  --devsite-caution-notice-color: #bf360c;
  --devsite-dogfood-notice-background: #eceff1;
  --devsite-dogfood-notice-color: #546e7a;
  --devsite-key-point-notice-background: #e8eaf6;
  --devsite-key-point-notice-color: #3f51b5;
  --devsite-key-term-notice-background: #f3e8fd;
  --devsite-key-term-notice-color: #9334e6;
  --devsite-note-notice-background: #e1f5fe;
  --devsite-note-notice-color: #01579b;
  --devsite-success-notice-background: #e0f2f1;
  --devsite-success-notice-color: #00796b;
  --devsite-warning-notice-background: #fce8e6;
  --devsite-warning-notice-color: #d50000;
  --devsite-confidential-results-background: rgba(254, 239, 227, 0.5);
  --devsite-notice-margin: 16px 0;
  --devsite-notice-padding: 16px 24px;
  --devsite-notice-padding-x-start: 60px;
  --devsite-book-nav-background: var(--devsite-background-1);
  --devsite-card-background: var(--devsite-background-1);
  --devsite-input-background: var(--devsite-background-1);
  --devsite-input-border: var(--devsite-secondary-border);
  --devsite-item-gap: 24px;
  // error image
  //   --devsite-select-background-image: url(data:image/svg + xml;utf8,
  //  <svgxmlns="http://www.w3.org/2000/svg" width="20" height="4" viewBox="0 0 20 4" ><pathd="M0,0l4,4l4-4H0z" fill="%23202124" /></svg>);
}

@import "./_variable";

body {
  overflow-x: hidden;
  background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h6 {
  color: $heading-text;
  letter-spacing: 0.8px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
  //font-family: "Fira Code", monospace;
}

h4,
h5 {
  letter-spacing: 0.8px;
  font-weight: 600;
  font-family: "Lato", sans-serif;
  color: $heading-text;
}

p {
  // font-family: "Fira Code", monospace;
  letter-spacing: 1px;
  color: #202124;
  font-size: 14px;
  font-family: "Akzidenz", "‘Helvetica Neue’", Helvetica, Arial, sans-serif;
}

i {
  cursor: pointer;
}

.red {
  color: rgb(150, 1, 38);
}

.green {
  color: rgb(46, 131, 12);
}

.blue {
  color: rgb(6, 67, 124);
}

.orange {
  color: $orange;
}

.voilet {
  color: #673de6;
}

.yellow {
  color: yellow;
}

.indigo {
  color: #a14072;
}

.bg-violet {
  background-color: $light-voilet !important;
}
.bg-primary {
  background-color: #673de6 !important;
}
.bg-green {
  background-color: rgb(46, 131, 12);
}

.bg-orange {
  background-color: $orange;
}

.bg-yellow {
  background-color: $dark-yellow;
}

.bg-pastel-green {
  background-color: $pastel-green;
}

.bg-pastel-pink {
  background-color: $pastel-pink;
}

.bg-pastel-purple {
  background-color: $pastel-purple;
}

.bg-pastel-blue {
  background-color: $pastel-blue;
}

.bg-pastel-orange {
  background-color: $pastel-orange;
}

.bg-pastel-red {
  background-color: $pastel-red;
}

.bg-section-light {
  background-color: $light_bg;
}

.dark-theme {
  background-color: $dark-theme;
}

// Border
.border-left-orange {
  border-left: 5px solid $orange;
  border-radius: 10px;
}

.border-left-green {
  border-left: 5px solid $dark-green;
  border-radius: 10px;
}

.border-left-voilet {
  border-left: 5px solid $light-voilet;
  border-radius: 10px;
}

.border-left-yellow {
  border-left: 5px solid $dark-yellow;
  border-radius: 10px;
}

.border-left-blue {
  border-left: 5px solid $dark-blue;
  border-radius: 5px;
}

.form-label {
  font-size: 14px;
  color: rgb(61, 79, 88);
  position: relative;
  // height: 16px;
  // width: 36px;
  font-weight: bold;
  line-height: 16px;
  display: inline;
  letter-spacing: 0.02em;
}

label {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.active {
  color: #673de6;
  font-weight: 600;
}

.active-with-border {
  color: #673de6;
  font-weight: 600;
  border-bottom: 2px solid #673de6;
}

.form-input-field {
  width: 100%;
  height: 36px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Akzidenz", "‘Helvetica Neue’", Helvetica, Arial, sans-serif;
  z-index: 1;
  color: rgb(33, 49, 60);
  background-color: rgb(255, 255, 255);
  padding-right: 12px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  transition: border-color 150ms ease-in-out 0s;
  outline: none;
  border-color: rgb(184, 196, 194);
}

.form-input-field-textarea {
  width: 100%;
  height: 360px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Akzidenz", "‘Helvetica Neue’", Helvetica, Arial, sans-serif;
  z-index: 1;
  color: rgb(33, 49, 60);
  background-color: rgb(255, 255, 255);
  padding-right: 12px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  transition: border-color 150ms ease-in-out 0s;
  outline: none;
  border-color: rgb(184, 196, 194);
}

input[type="text" i] {
  padding: 1px 7px;
}

input[type="password" i] {
  padding: 1px 7px;
}

input[type="textArea"] {
  padding: 1px 7px;
  height: 300px;
}

input[type="checkbox"] {
  padding: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

input[type="radio"] {
  padding: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

input {
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: textfield;
  cursor: text;
  margin: 0em;
  font: 400 13.3333px Arial;
}

textarea {
  max-height: 100px;
}

.radio-emoji-input {
  /* HIDE RADIO */
  [type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type="radio"] + i {
    cursor: pointer;
    padding: 10px;
    background-color: #f1f3f4;
    border-radius: 5px;
    color: $dark-yellow;

    &:hover {
      background-color: #fffbda;
    }
  }

  /* CHECKED STYLES */
  [type="radio"]:checked + i {
    padding: 10px;
    background-color: #c9edf8dc;
    border-radius: 5px;
    color: #3f51b5;
  }
}

.field-error {
  font-size: 10px;
  font-family: "Akzidenz", "‘Helvetica Neue’", Helvetica, Arial, sans-serif;
  color: red;
}

.link {
  color: blue;
}

.left-hr-line {
  border: solid 5px #30006d;
  width: 200px;
  text-align: left;
  align-items: left;
  justify-items: left;
  border-radius: 5px;
  margin-left: -10px;
}

.right-hr-line {
  border: solid 5px #30006d;
  width: 200px;
  text-align: right;
  align-items: right;
  justify-items: right;
  justify-content: right;
  border-radius: 5px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.nextBtn,
.previousBtn {
  padding: 10px;
  background-color: #30006d;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 15px;
  outline: none;
}

.page {
  padding: 10px;
  background-color: #212529;
  border-radius: 8px;
  color: #ffffff;
  margin: 0 10px 0 10px;
  outline: none;
  cursor: pointer;

  :hover {
    color: rgb(184, 196, 194);
    cursor: pointer;
  }
}

.activeClassLink {
  background-color: #d4bef1;
  color: #30006d !important;
}

.disabled {
  background-color: #686868;
  color: #ffffff;
}

.post-card {
  h5 {
    color: #30006d;
    font-weight: 600;
    font-size: 15px;
    font-family: "Akzidenz", "‘Helvetica Neue’", Helvetica, Arial, sans-serif;
  }

  p {
    font-size: 13px;
  }

  span {
    font-weight: 600;
    color: #686868;
    font-size: 14px;
  }

  .tech-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-height: 400px;
  }

  .tech-card:hover {
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
  }

  .tech-card img {
    transition: transform 0.3s ease;
  }

  .tech-card:hover img {
    transform: scale(1.05);
  }

  .btn-gradient {
    background: linear-gradient(45deg, #4158d0, #c850c0, #ffcc70);
    border: none;
    color: white;
    transition: all 0.3s ease;
    background-size: 200% auto;
    font-size: 16px;
  }

  .btn-gradient:hover {
    background-position: right center;
    color: white;
    transform: translateY(-2px);
  }
}

a {
  text-decoration: none;
}

.material-icons {
  display: inline-flex;
  vertical-align: top;
}

.react-multiple-carousel__arrow--right::before {
  content: "\f0a9";
  padding-top: 1px;
  font-family: "Font Awesome 5 Pro";
}

.react-multiple-carousel__arrow--left::before {
  content: "\f0a8";
  padding-top: 1px;
  font-family: "Font Awesome 5 Pro";
}

.card-schadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.button-filled {
  background-color: $light-voilet;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1px;
  height: 40px;
  border: solid 1px #ffffff;
  margin-top: 35px;
  align-items: center;

  &:hover {
    color: #ffffff;
    opacity: 0.9;
  }

  outline: none !important;
}

.color-tooltip {
  span {
    font-size: 14px;
  }
}

.upload-block {
  min-height: 100px;
  width: 100%;
  border: dashed 2px #726f77;
  align-items: center;
  justify-content: center;

  i {
    align-items: center;
    font-size: 40px;
    position: relative;
  }
}

.public-DraftStyleDefault-ltr {
  margin: 1px;
}

.rdw-editor-main {
  padding: 20px;
}

.check-mark-list {
  ul {
    li {
      list-style: none;

      span {
        font-size: 16px;
      }
    }
  }

  font-weight: 300;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 20px 0 5px 25px;

      &::before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        margin-top: 2px;
        margin-left: -25px;
        display: table;
        float: left;
        color: $light-voilet;
        font-weight: 500;
      }
    }
  }
}

.glow-btn {
  border: 1px transparent;
  -webkit-border-radius: 50px;
  border: solid 2px #ffffff;
  border-radius: 50px;

  small {
    color: $black;
    font-size: 12px;
    line-height: 12px;
  }

  color: $black;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  text-align: center;
  text-decoration: none;
  margin-left: 20px;
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}

.custom-border-capsule-button {
  border: 2px solid $light-voilet;
  background-color: $light-voilet;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 500;
  margin-top: 35px;
  min-width: 100px;
  height: 40px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
  overflow: hidden;
  align-items: center;

  &:hover {
    color: #ffffff;
    border: 2px solid $light-voilet;
    background-color: $light-voilet;
    opacity: 0.9;
    box-shadow: 0px 0px 2px $black;
  }
}

.custom-border-button {
  border: 1px solid $heading-text;
  background-color: #ffffff;
  border-radius: 5px;
  color: $heading-text;
  font-weight: 400;
  height: 40px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
  overflow: hidden;
  align-items: center;

  &:hover {
    color: $light-voilet;
    font-weight: 500;
    border: 1px solid $light-voilet;
    box-shadow: 0px 0px 2px $black;
  }
}

.pointer {
  cursor: pointer;
}

@-webkit-keyframes glowing {
  0% {
    background-color: #00b253;
    -webkit-box-shadow: 0 0 3px #00b253;
  }

  50% {
    background-color: #21c7ed;
    -webkit-box-shadow: 0 0 15px #21c7ed;
  }

  100% {
    background-color: #daff0a;
    -webkit-box-shadow: 0 0 3px #daff0a;
  }
}

@keyframes glowing {
  0% {
    background-color: #00b253;
    box-shadow: 0 0 3px #00b253;
  }

  50% {
    background-color: #21c7ed;
    box-shadow: 0 0 15px #21c7ed;
  }

  100% {
    background-color: #daff0a;
    box-shadow: 0 0 3px #daff0a;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #4b89dc;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 3px;
  background-color: transparent;
  border: 1px solid #2196f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: transparent;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch input:checked + .slider:before {
  background: #4b89dc;
  border: 1px solid transparent;
  left: -9px;
}

.mentor-card {
  img {
    width: 80px;
    height: 80px;
  }
}

.border-dotted {
  // border-style: ;
  border-radius: 5px;
  border: 2px dotted #726f77;
}

.progress {
  height: 10px;
}

// Timer Clock
.odometer-block {
  background-color: #f1f3f4;
  border-radius: 5px;
  padding: 15px;

  span {
    display: inline-grid;
    justify-content: space-between;
    font-weight: 300;
    color: #000;
  }

  .day,
  .hour,
  .min,
  .sec {
    border: 1px solid $black;
    padding: 10px;
    border-radius: 5px;
    font-family: "Dosis", sans-serif !important;
    font-weight: 300;
    font-size: 35px;
    display: inline;
    margin: 0 25px 5px 25px;
  }
}

// Bootstrap-table

.bootstrap-table {
  font-size: 15px;
  background: #ffffff;

  .table-header {
    border-bottom: 0 !important;
    font-size: 14px;
    font-weight: 600;
  }

  .active {
    color: #4b89dc;
  }

  .action-border {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      height: 100%;
      width: 1px;
      background: rgba(0, 0, 0, 0.1);
      left: 7px;
      top: 0;
    }
  }

  .s-no {
    width: 50px;
  }

  .clock {
    width: 70px;
  }

  .view-order {
    width: 150px;
  }

  .userName {
    width: 100px !important;
  }

  .name {
    width: 150px;
  }

  .mentors {
    width: 150px;
  }

  .est-delivery {
    width: 150px;
  }

  .status {
    width: 120px;
  }

  .group-name {
    width: 130px;
  }

  .group {
    width: 100px;
  }

  .action {
    width: 70px;
  }

  .tags {
    width: 120px;
  }

  .description {
    width: 250px;
  }

  .w-200 {
    width: 200px;
  }
}

// Custom Calendar
.react-add-to-calendar {
  cursor: pointer;

  .react-add-to-calendar__wrapper {
    padding: 10px;
    border-radius: 5px;

    a {
      text-decoration: none;
    }
  }

  justify-content: center;
  align-items: center;

  .react-add-to-calendar__dropdown {
    padding: 10px 0;
    border: 1px solid $base-font-color;
    border-radius: 5px;

    ul {
      padding: 0;
      text-align: left;

      li {
        list-style: none;
        padding: 5px 10px;

        i {
          padding-right: 10px;
        }

        a {
          color: $base-font-color;
        }

        &:hover {
          background-color: $light-voilet;

          a {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}

//  Custom Filter Search

.custom-filter-search {
  .fontSearch {
    justify-content: center;
    text-align: center;
    position: relative;
    width: 100%;
  }

  .fontSearch i {
    position: absolute;
    left: 35px;
    top: 16px;
    color: gray;
  }

  .search {
    padding: 10px 50px 10px 50px;
    height: 50px;
    width: 100%;
    border: solid 2px #797676;
    border-radius: 5px;
    outline: none;
  }

  .search-icon {
    align-items: center;
    font-size: 20px;
    color: #30006d;
  }

  margin-top: 40px;

  .custom-filter-search-body {
    z-index: 9;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    border: 1px solid #dee2e6;
    border-radius: 10px;

    .text-left {
      margin-top: 0 !important;
    }

    .form-input-field {
      border: none;
      font-size: 20px;
      margin-top: 0;
    }
  }
}

// Custom Multiple Text typewriter text animation

.multiple-text-typewriter {
  display: inline-block;

  > span {
    display: grid;
    overflow: hidden;
    height: 1.2em;
  }

  span {
    span {
      width: 0%;
      max-width: max-content;
      overflow: hidden;
      height: inherit;
      word-break: break-all;
      animation: c 0.5s infinite steps(1), t 2s linear infinite alternate,
        m 16s steps(4) infinite;

      // set 2s for type and 2s for remove 4s for each (4s * 4 steps == 16s for (m))
      &:before {
        content: " ";
        display: inline-block;
      }
    }
  }

  @keyframes t {
    80%,
    100% {
      width: 100%;
    }
  }

  @keyframes c {
    0%,
    100% {
      box-shadow: 5px 0 0 #0000;
    }

    50% {
      box-shadow: 5px 0 0 red;
    }
  }

  @keyframes m {
    100% {
      transform: translateY(-400%); // Set {-500%} if you have 5 span items
    }
  }
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: 20px;

  .page-number {
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    color: $dark-grey;
    background-color: $dark-base;
    border-radius: 5px;

    &.active {
      background-color: $light-voilet;
      color: white;
      font-weight: 600;
    }
  }

  .prev,
  .next {
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
    background-color: $base-font-color;
    color: #fff;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

///////////////////////////////////////////////////////////////

@media (max-width: 767px) {
  body {
    overflow-x: hidden;
  }

  .tooltip-inner {
    display: none;
  }

  .color-tooltip,
  .info {
    display: none;
  }

  // Timer Clock
  .odometer-block {
    background-color: #f1f3f4;
    border-radius: 5px;
    padding: 15px;

    span {
      display: inline-grid;
      justify-content: space-between;
      font-weight: 300;
      color: #000;
    }

    .day,
    .hour,
    .min,
    .sec {
      border: 1px solid $black;
      padding: 10px;
      border-radius: 5px;
      font-family: "Dosis", sans-serif !important;
      font-weight: 300;
      font-size: 27px;
      display: inline;
      margin: 0 10px 5px 10px;
    }
  }
}
