#video-top{
    margin-top:20px;
    text-align:center;
}

#youtube-video{
    display: flex;
    flex-direction:row;
    margin-top:30px;
    align-content: space-between;
    box-shadow: 2px 2px 10px #000a1a;
    
}

#video-id{
    height:390px;
    width:690px;
    padding:10px;
    margin-left:-14px;
    margin-bottom:-7px;
}

#content{
    height:390px;
    padding:10px;
}



@media only screen and (max-width: 700px) {
    #youtube-video{
        flex-direction:column;
        margin-left:-10px;
        width:350px;
        align-content: center;
    }

    #video-id{
       width:340px;
       height:260px ;
       margin-left:-10px;
       margin-top:5px;
    }
    #content{
        width:340px;
        height:500px;
        margin-left:-10px;
        margin-right:50px;
        margin-top:5px;
        display:flex;
        flex-direction: column;
    }
   h1{
       margin-top:10px;
       font-size:30px;
   }
}


