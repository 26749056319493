@import "./_variable";

.landing-carousel {
  .carousel-control-prev,
  .carousel-control-next {
    position: relative;
  }
}

.carousel-card {
  min-height: 500px;
  width: auto;
  background: rgb(48, 0, 182);
  background: rgb(48, 0, 182);
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 11%,
    rgb(255, 255, 255) 35%,
    rgb(255, 255, 255) 57%
  );

  h1 {
    font-size: 55px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h6 {
    color: $heading-text;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }

  h3 {
    color: $light-voilet;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px !important;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }

  h2 {
    color: #fdd023;
    font-size: 25px;
    margin-top: 20px;
  }

  /* Animation */
  h3 {
    animation: animated-text 4s steps(29, end) 1s 1 normal both,
      animated-cursor 600ms steps(29, end) infinite;
  }

  padding: 5% 12% 0 12% !important;

  /* text animation */

  @keyframes animated-text {
    from {
      width: 0;
    }

    to {
      width: 472px;
    }
  }

  /* cursor animations */

  @keyframes animated-cursor {
    from {
      border-right-color: rgba(0, 255, 0, 0.75);
    }

    to {
      border-right-color: transparent;
    }
  }

  .heading-second {
    font-size: 28px;
  }

  .qoute {
    font-size: 15px;
    color: $light-voilet;
    margin-top: 50px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }

  .quote-icon {
    position: relative;
    font-size: 40px;
  }

  .get-started-button {
    //height: 20px;
    width: 50%;
    background-color: #ffffff;
    border-radius: 50px;
    color: #30006d;
    font-weight: 500;
    padding: 10px;
    border: solid 1px #30006d;
    letter-spacing: 1px;
    margin-top: 80px;

    &:hover {
      background-color: $light-voilet;
      color: #ffffff;
      border: none;
    }
  }
}

.community-section {
  p {
    font-size: 15px;
    padding: 20px;
  }
}

.join-us-button {
  background-color: $light-voilet;
  color: #ffffff;
  border: solid 1px #ffffff;
  font-weight: 400;
  padding: 10px 30px;
  letter-spacing: 1px;
  margin-top: 35px;
  align-items: center;
  transition: 0.3s;
}

.join-us-button:hover {
  background-color: #ffffff;
  border: solid 1px #30006d;
  color: #30006d;
}

.aboutus-section {
  .find-out-more {
    a {
      text-decoration: none;
    }

    height: auto;
    width: 300px;
    justify-content: center;
    background-color: $light-voilet;
    color: white;
    font-size: 400;
    border-radius: 5px;
    cursor: pointer;

    .find-out-more {
      vertical-align: bottom;
      color: #ffffff;
      font-weight: 800;
      text-align: right;
      justify-content: right;
    }
  }

  p {
    font-size: 15px;
    padding: 20px;
  }

  .tag-lines {
    span {
      justify-content: space-between;
      align-content: initial;
      margin-right: 15px;
      font-weight: 600;
    }
  }
}

.category-section {
  h2 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h6 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }
  .category-card {
    h6 {
      font-size: 18px;
      font-weight: 600;
      color: $heading-text;
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
    }

    p {
      font-size: 14px;
      color: #212529;
      letter-spacing: 0.8px;
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
  }

  .card-shadow {
    border: none;
    box-shadow: 0px 1px 6px 1px rgba(59, 73, 94, 0.2);
    height: auto;
    border-radius: 5px;
  }
}

.community-announcement {
  color: #ffffff;

  .community-event {
    border-radius: 20px;
    min-height: 350px;
    color: #ffffff;
  }

  .community-event-bg-img {
    background-image: url("../images/bg/Wittyhacks-bg.png");
  }
  .datacode-accouncement {
    h1 {
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
  }
}

.news-letter-section {
  align-items: center;

  .subscribe-form {
    justify-content: center;
    // background: rgb(48, 0, 182);
    // background: linear-gradient(
    //   180deg,
    //   rgba(48, 0, 182, 1) 11%,
    //   rgba(48, 0, 182, 1) 35%,
    //   rgba(79, 34, 209, 1) 57%,
    //   rgba(92, 48, 220, 1) 66%,
    //   rgba(103, 61, 230, 1) 74%,
    //   rgba(103, 61, 230, 1) 88%
    // );
    padding: 0 100px 0 100px;

    // border-bottom-left-radius: 100px;
    // border-top-left-radius: 100px;
    input {
      border: solid 1px $heading-text;
      height: 50px;

      &::placeholder {
        color: $base-font-color;
        font-size: 14px;
        font-weight: 400;
      }

      &:-ms-input-placeholder {
        color: $base-font-color;
        font-size: 12px;
        font-weight: 600;
      }

      &::-ms-input-placeholder {
        color: $base-font-color;
        font-size: 12px;
        font-weight: 600;
      }
    }

    label {
      color: #ffffff;
    }
  }

  .subscribe-text {
    justify-content: center;

    h2 {
      font-size: 35px;
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      font-size: 15px;
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
  }

  .subscribe-button {
    width: 250px;
    height: 55px;
    background-color: $light-voilet;
    color: #ffffff;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    border: solid 1px #ffffff;
    outline: none;

    &:hover {
      background-color: #ffffff;
      border: solid 1px $light-voilet;
      color: $light-voilet;
    }
  }
}

.thanks-for-subscribing {
  justify-content: center;
  align-items: center;

  h2 {
    color: #ffffff;
    font-size: 25px;
    text-align: center;
  }
}

// explore our learning process
.exploreOurLearningProcess {
  img {
    border-radius: 15px;
  }
}

// program that mahe diff
.programThatMakeDiff {
  .container {
    .card {
      border-radius: 15px;

      img {
        border-radius: 15px 15px 0px 0px;
      }

      // .btn {
      //   font-size: 15px;
      //   font-weight: 500;
      // }
    }
    h2 {
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
    }
    h5 {
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
    }
    p {
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
  }
}
.grow-learning-community {
  h1 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h6 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }
}
// moments of fun
.lifehere {
  p {
    font-size: 18px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  h2 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .story-right {
    // @include bg-img("../images/community/story-section-bg.png");
    background-image: url("../images/community/story-section-bg.png");
    // @include bg-img{`http://res.cloudinary.com/datacode/image/upload/v1731595867/ajvyhv3mgmbpqg6jwzfq.png`};
    position: relative;
    min-height: 415px;

    .story-container {
      position: absolute;
      width: 130%;
      top: 14%;
      left: -20%;
    }

    .story-item {
      // max-width: 400px;
      background-color: white;
      padding: 5px;
      // margin: 0 10px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }

    img {
      border-radius: 10px;
    }
  }
}

// success stories
.projects {
  h2 {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .success-card-text {
    h4,
    h6 {
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
    }
    p {
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
  }

  .container {
    .box1 {
      min-height: 300px;
      border: 1px solid #7848f4;
      border-radius: 15px 0px 0px 15px;

      .projectimg {
        border-radius: 10px 10px 0px 0px;
      }
    }

    .box2 {
      min-height: 300px;
      background-color: #7848f4;
      border-radius: 0px 15px 15px 0px;

      .learn-more {
        text-decoration: none;
        background-color: #ffffff;
        color: #7848f4;
        border-radius: 20px;
        font-size: 15px;
        font-weight: 700;
      }

      p {
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .category-section {
    h2 {
      font-size: 24px;
    }
  }

  .carousel-card {
    height: auto;

    h1 {
      text-align: left;
      font-size: 60px;
    }

    h2 {
      font-size: 17px;
    }

    .heading-second {
      font-size: 20px;
    }

    .qoute {
      display: none;
    }

    .get-started-button {
      margin-top: 5px;
    }

    padding: 0 2% !important;
  }

  .news-letter-section {
    height: auto;
    border-top: solid 2px #673de6;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .subscribe-form {
      min-height: 200px;
      padding: 0 30px 0 30px;
      border-bottom-right-radius: 100px;
      border-bottom-left-radius: 100px;
      border-top-left-radius: 0px;
    }

    .subscribe-text {
      padding-top: 20px;

      h2 {
        font-size: 20px;
      }

      p {
        font-size: 12px;
      }
    }
  }
  .community-announcement {
    .datacode-accouncement {
      h1 {
        font-size: 32px;
        text-align: center;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .programThatMakeDiff {
    .container {
      h2 {
        font-size: 32px;
      }
      p {
        font-size: 16px !important;
      }
    }
  }
  .grow-learning-community {
    h1 {
      font-size: 32px;
    }
    h6 {
      font-size: 14px !important;
    }
    .grow-card {
      h5 {
        font-size: 32px !important;
      }
      p {
        font-size: 16px !important;
      }
    }
  }
  .category-section {
    h2 {
      font-size: 32px;
    }
    .category-card {
      h6 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .lifehere {
    h2 {
      font-size: 32px !important;
      text-align: center;
    }
  }
  .news-letter-section {
    .subscribe-text {
      h2 {
        font-size: 32px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 480px) {
  .programThatMakeDiff {
    h2 {
      font-size: 28px;
    }

    // .container {
    //   min-height: 300px;
    // }
  }

  // moments of fun
  .lifehere {
    h2 {
      font-size: 28px;
    }

    p {
      letter-spacing: 0px;
    }

    .story-right {
      min-height: 300px;

      .story-container {
        width: 100%;

        .story-item {
          img {
            height: 200px;
            width: 280px;
          }
        }
      }
    }
  }

  .news-letter-section {
    h2 {
      font-size: 28px;
    }
  }

  // success stories
  .projects {
    .para {
      letter-spacing: 0px;
    }

    .container {
      .box1 {
        border-radius: 15px 15px 0px 0px;

        .projectimg {
          border-radius: 10px 10px 10px 10px;
        }
      }

      .box2 {
        border-radius: 0px 0px 15px 15px;
        h4 {
          font-size: 28px;
        }
        h6 {
          font-size: 22px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}
