@import "./_variable";
// Common CSS -------------------------
p {
  color: $text-color;
  font-size: $fs-body;
}

.padding-x {
  padding-right: $padding-x;
  padding-left: $padding-x;
}

.padding-y {
  padding-top: $padding-y;
  padding-bottom: $padding-y;
}

@mixin flex($justify: center, $align: center, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin bg-radial($direction) {
  background: #f5c7ff82;
  background: radial-gradient(
    at $direction center,
    rgba(245, 199, 255, 0.51),
    rgba(217, 217, 217, 0)
  );
}

.community-heading {
  font-weight: 400;
  font-size: $fs-heading;
}

.btn {
  font-size: $fs-btn;
  padding: 10px 40px;
  border-radius: 6.4px;
  border: 1px solid;
  font-weight: 600;
  width: fit-content;
}

.btn-primary {
  @extend .btn;
  background-color: $primary-color;
  color: white;
  border-color: $primary-color;
}

.btn-secondary {
  @extend .btn;
  background-color: white;
  color: $primary-color;
  border: none;
}

.btn-outline {
  @extend .btn;
  border-color: $primary-color;
  background-color: white;
  color: $primary-color;
}

@mixin bg-img($url, $size: cover) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: $size;
}

.community-page {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 16px;
}

.gap-2 {
  gap: 32px;
}

.gap-3 {
  gap: 48px;
}

.gap-4 {
  gap: 64px;
}

.gap-5 {
  gap: 80px;
}

.gap-md-0 {
  gap: 0;
}

.gap-md-1 {
  gap: 16px;
}

.gap-md-2 {
  gap: 32px;
}

.gap-md-3 {
  gap: 48px;
}

.gap-md-4 {
  gap: 64px;
}

.gap-md-5 {
  gap: 80px;
}

.gap-lg-0 {
  gap: 0;
}

.gap-lg-1 {
  gap: 16px;
}

.gap-lg-2 {
  gap: 32px;
}

.gap-lg-3 {
  gap: 48px;
}

.gap-lg-4 {
  gap: 64px;
}

.gap-lg-5 {
  gap: 80px;
}
// hero-section

.hero-section {
  height: 120vh;
  @media (min-width: 992px) {
    @include bg-img("../images/community/hero-section-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  h1 {
    font-size: 48px !important;
    font-weight: 700;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    color: transparent;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    margin-top: -60px;
  }
  p {
    font-size: 18px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  .engaging-text {
    font-size: 32px;
    position: absolute;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
    bottom: -20px;
    font-weight: 700;
  }
}

// Counter section -------------------
.counter-section {
  h1 {
    color: white;
    text-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $primary-color;
    font-size: 128px;
    font-weight: 600;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }

  span {
    font-size: 56px;
  }

  p {
    font-weight: 600;
    color: black;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }
}

// Quote section -----------------------
.quote-section {
  margin-left: $padding-x;
  margin-right: $padding-x;

  .quote {
    @include bg-img("../images/community/quote-section-bg.png");
    background-position: center;
    border-radius: 43px;
    padding-inline: 40px;

    p {
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
      font-size: 25px;
      color: $white;
      font-weight: 400;
      padding: $padding-x - 1%;
    }

    > div {
      height: 120px;
      width: 770px;
    }
  }

  .video {
    position: relative;
    bottom: 150px;

    iframe {
      box-shadow: 0px 15px 29.2px 0px $black;
      border-radius: 15px;
      width: 50%;
      height: 300px;
    }
  }
}

// About section --------------------
.about-section {
  h1 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    font-size: $font-size-of-normal-h1;
    font-weight: 400;
    line-height: 40.25px;
    text-transform: uppercase;
    span {
      color: transparent;
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
    }
  }

  p {
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
    font-size: 18px;
    font-weight: 400;
  }

  .about-header {
    @include flex($direction: column);

    .community-heading {
      text-transform: uppercase;
    }
  }

  .about-container {
    background: linear-gradient(
      270.04deg,
      #7848f4 41.85%,
      rgba(231, 154, 250, 0) 134.76%
    );

    .about-left {
      position: relative;
      z-index: -10;

      img {
        width: 100%;
        height: auto;
      }
    }

    .about-right {
      padding: 3%;
      @include flex($justify: space-between, $direction: column);

      .about-card {
        @include flex(flex-start, start);
        text-align: left !important;
        background-color: white;
        padding: 20px;

        p {
          font-size: 12px;
          padding-left: 32px;
        }

        img {
          width: 25px;
          // margin-top: 5px;
        }

        h3 {
          font-size: 14px;
          font-family: $font-headings;
          letter-spacing: $heading-letter-spacing;
          line-height: $heading-line-height;
        }
      }
    }
  }
}

// Join community section -----------------
.join-community-section {
  @include bg-radial(right);
  padding-top: $padding-y + 5%;
  padding-bottom: $padding-y + 5%;

  h1 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }

  p {
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
    font-weight: 400;
  }

  img {
    width: 150.33px;
    opacity: 0px;
  }
  .img-middle {
    bottom: 30px;
  }

  .icon {
    position: absolute;
    left: -50px;

    height: fit-content;
    width: 100px;
  }
}

// Event Section ------------------------
.event-section {
  .card-section {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ffffff;
    box-shadow: 0px 11px 22px 0px rgba(164, 164, 164, 0.25);
  }

  h1 {
    font-size: $font-size-of-normal-h1;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    span {
      color: transparent;
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
    }
  }

  h6 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }

  p {
    font-size: 14px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  .event-link {
    color: $primary-color;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;

    img {
      width: 10px;
      margin-left: 5px;
    }
  }

  .event-right {
    img {
      width: 80%;
      height: 80%;
    }
  }
}

// Volunteer section ---------------------
.volunteer-section {
  background: rgba(120, 72, 244, 0.72);
  margin: 7% 0;

  h1 {
    font-size: $font-size-of-normal-h1;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    span {
      color: transparent;
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
    }
  }
  p {
    color: white;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  img {
    max-width: 354px;
  }
}

// Contributor Section -------------------
.contributor-section {
  .line {
    width: 80px;
    height: 4px;
    background-color: #cacaca;
    margin: 20px auto;
  }

  .contributor-container {
    gap: 80px;
    margin-top: 60px;

    .contributor-img {
      width: 140px;
    }
    h5 {
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
    }
    p {
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
  }

  h1 {
    font-size: $font-size-of-normal-h1;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    span {
      color: transparent;
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
    }
  }
}

// Contribute section ------------------------
.contribute-section {
  background: rgba(0, 0, 0, 0.06);
  margin: 9% 0;

  img {
    width: 470px;
    filter: drop-shadow(5px 5px 5px rgba(177, 177, 177, 0.603));
  }

  h1 {
    font-size: $font-size-of-normal-h1;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    span {
      color: transparent;
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
    }
  }
  p {
    max-width: 500px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  .community-card-header {
    img {
      width: 35px;
      top: -25px;
      left: 47%;
    }
  }
  .plane-img {
    top: -60px;
    right: 280px;
  }
}

// Story section -----------------------
.story-section {
  padding-left: $padding-x;

  .story-left {
    p {
      line-height: 35px;
      font-size: 23px;
      max-width: 460px;
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }

    h1 {
      font-size: $font-size-of-normal-h1;
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
      span {
        color: transparent;
        background: linear-gradient(to right, #7848f4, black);
        -webkit-background-clip: text;
      }
    }

    img {
      width: 125px;

      filter: drop-shadow(1px 1px #3e66dfa0);
    }
  }

  .story-right {
    @include bg-img("../images/community/story-section-bg.png");
    position: relative;
    min-height: 380px;

    .story-container {
      position: absolute;
      width: 122.5%;
      top: 18%;
      left: -20%;
    }

    .story-item {
      background-color: white;
      padding: 20px;
      margin: 0 10px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      min-height: 260px;

      img {
        width: 60px;
        height: 60px;
      }

      .story-header {
        h6 {
          color: $primary-color;
          font-weight: 600;
          font-family: $font-headings;
          letter-spacing: $heading-letter-spacing;
          line-height: $heading-line-height;
        }

        p {
          font-size: 12px;
          font-family: $font-text;
          letter-spacing: $text-letter-spacing;
          line-height: $text-line-height;
        }
      }

      p {
        font-size: 14px;
        font-family: $font-text;
        letter-spacing: $text-letter-spacing;
        line-height: $text-line-height;
      }
    }
  }
}

// Team Section ----------------------------
.team-section {
  background-color: #9e7bf7;
  margin: 9% 0;

  h1,
  h3 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;

    color: white;
    font-weight: 700;
  }
  p {
    color: white;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  button {
    color: $primary-color !important;
    background-color: $btn-font-color !important;
    border-radius: $btn-border-radius !important;
    font-size: medium;
  }

  .team-left {
    justify-content: center;
    display: grid;
    grid-template-columns: 150px 150px 150px;
    gap: 18px;

    .team-img {
      max-width: 150px;
      border-radius: 26px;

      &:nth-child(3),
      &:nth-child(4) {
        border-radius: 50%;
      }
    }
  }
}

// Community Partners Section ----------------------
.community-partner-section {
  h3 {
     
    font-size: $font-size-of-normal-h1;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    span {
      color: transparent;
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
    }
 
  }
  .community-partner-container {
    background: linear-gradient(
      180deg,
      rgba(120, 72, 244, 0.5) 0%,
      rgba(241, 245, 249, 0) 100%
    );
    box-shadow: 2px 2px 10px 0px $black;
    padding: $padding-y $padding-x;
    margin: $padding-x 0;

    img {
      width: 160px;
    }
  }
}

//gallery-section

.gallery-section {
  background-color: #9e7bf7;
  border-radius: 5px;
  padding: 40px;
  box-shadow: inset 0 0 70px rgba(255, 255, 255, 1);

  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    img {
      width: 140px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 0px, 0px, 7px, 7px;
    }
  }

  h3 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 500;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }

  p {
    color: #ffffff;
    font-size: 18px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  // button {
  //   color: $primary-color !important;
  //   background-color: $btn-font-color !important;
  //   border-radius: $btn-border-radius !important;
  // }
}

// Community Program Section -----------------------
.community-program-section {
  .btn-1 {
    background-color: $primary-color !important;
    color: $btn-font-color !important;
    border-radius: $btn-border-radius !important;
  }

    h1 {
      font-size: $font-size-of-normal-h1;
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
      span {
        color: transparent;
        background: linear-gradient(to right, #7848f4, black);
        -webkit-background-clip: text;
      }
    }
  
  h5 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    font-weight: 700;
  }
  p {
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }
  .program-container {
    // background: #7848f480;
    @include bg-img("../images/community/story-section-bg.png");
    // background: linear-gradient(50deg, #7848F480 100%, #7848F480  100%);
    margin: 2% 0;
    padding: $padding-x;

    .icon {
      font-size: 60px;
      opacity: 0px;
      font-weight: 100;
    }

    .card {
      border-radius: 4px;
      // border: 4px solid #b75bff63;
      box-shadow: 0px 5px 10px -3px $black;

      .program-card-header {
        @include flex(space-between);

        img {
          width: 100px;
        }
      }
    }
  }
}

// Footer section -------------------------------
.footer {
  h1 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    .span-1 {
      color: $primary-color;
    }
  }

  h6 {
    color: #6c6c72;
    font-size: 15px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }

  // button {
  //   background-color: $primary-color !important;
  //   color: $btn-font-color !important;
  // }
  p {
    max-width: 400px;
  }

  img {
    width: 80%;
  }
}

----------------------------------------------------------------------------------------------------

// internship-program section start

// internship-program file

.internship-program {
  background: linear-gradient(0deg, #ffffff, #956cf8);

  h1 {
    font-weight: 800;
    // background: linear-gradient(#7848f4, rgba(240, 188, 252, 0.51));
    // -webkit-background-clip: text;
    // color: transparent;
    font-size: 72px;
  }

  h5 {
    font-size: 28px;
    font-weight: 600;
  }
}

// hero-section

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.internship-hero-section {
  .icon-section{
    font-size: 200px;
  }
  background-color: #ffffff;
  .title {
    font-size: 92px;
    font-weight: 800;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
    color: transparent;
    line-height: 1.2;
  }

  .quote {
    color: #7848f4;
    font-size: 28px;
  }

  .para {
    font-size: 22px;
    font-weight: 600;
    color: #6c6c72;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }

  // .apply-now {
  //   color: #ffffff;
  //   background-color: #7848f4;
  //   border-radius: 25px;
  //   font-size: 22px;
  //   border: none;
  // }
}

// first step towards future

.firstStep {
  h1 {
    font-size: 32px;

    span {
      color: #7848f4;
    }
  }

  .para {
    font-size: 18px;
    font-weight: 500;
  }

  .peopleimg {
    border-radius: 15px;
  }
}

// become-professional file

.become-professional {
  h1 {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;

    span {
      color: #7848f4;
    }
  }

  .our-internship {
    // background: rgba(120, 72, 244, 0.72);

    .container {
      background-color: #ffffff;
      border-radius: 15px;
      min-height: 250px;

      .icon {
        font-size: 46.4px;
        background-color: #7848f4;
        color: #ffffff;
      }
      p {
        font-size: 15px;
        color: #4b5563;
        font-family: $font-text;
        letter-spacing: $text-letter-spacing;
        line-height: $text-line-height;
      }
      h5 {
        font-family: $font-headings;
        letter-spacing: $heading-letter-spacing;
        line-height: $heading-line-height;
      }
    }
  }
}

// registration-section
.registration-section {
  h1 {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;

    span {
      color: #7848f4;
    }
  }

  h5 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    span {
      color: #7848f4;
      font-size: larger;
    }
  }

  .box-x1 {
    // @include bg-radial(right);
    background-color: #956cf8;
    border-radius: 15px;
    min-height: 130px;

    .para {
      font-size: 16px;
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }

    .box-shadow-team {
      background-color: white;
      box-shadow: 0 1px 3px rgba(240, 240, 240, 0.5),
        /* Light dusky white shadow */ 0 2px 5px rgba(220, 220, 220, 0.4),
        /* Slightly darker dusky shadow */ 0 4px 10px rgba(200, 200, 200, 0.3),
        /* More blurred dusky shadow */ 0 8px 20px rgba(180, 180, 180, 0.2);
      /* Largest shadow for depth */
    }

    .number {
      background-color: #ffffff;
      color: #7848f4;
      font-weight: 700;
    }
  }

  img {
    border-radius: 15px;
  }
}

// technologies

.technology {
  .heading {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    span {
      color: #7848f4;
    }
  }
  h4 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }
}

// events

.events {
  h1 {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;

    span {
      color: #7848f4;
    }
  }

  .peopleimg {
    border-radius: 15px;
  }

  .para {
    font-size: 18px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }
}

// our-culture

.our-culture {
  .heading {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;

    span {
      color: #7848f4;
    }
  }

  .para {
    font-size: 18px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }
}

// our values

.our-value {
  .heading {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;

    span {
      color: #7848f4;
    }
  }
  h5 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }
  p {
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }
  .container {
    // background-color: #ffffff;
    @include bg-img("../images/community/story-section-bg.png");
    border-radius: 15px;
    min-height: 230px;

    .para {
      font-size: 15px;
    }
  }
}

// life here

.life-here {
  h1 {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;

    span {
      color: #7848f4;
    }
  }

  p {
    font-size: 18px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  .story-right {
    @include bg-img("../images/community/story-section-bg.png");
    // @include bg-img{`http://res.cloudinary.com/datacode/image/upload/v1731595867/ajvyhv3mgmbpqg6jwzfq.png`};
    position: relative;
    min-height: 415px;

    .story-container {
      position: absolute;
      width: 130%;
      top: 14%;
      left: -20%;
    }

    .story-item {
      // max-width: 400px;
      background-color: white;
      padding: 5px;
      // margin: 0 10px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }

    img {
      border-radius: 10px;
    }
  }
}

//  JOIN US FILE
.join-us {
  // @include bg-radial(right);
  h1 {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;

    span {
      color: #7848f4;
    }
  }

  p {
    font-size: 16px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  // button {
  //   background: $primary-color !important;
  //   color: $btn-font-color !important;
  //   border-radius: $btn-border-radius !important;
  //   font-family: "Montserrat";
  //   border: none;
  // }
}

// hiring Partnes
.hiring-partners {
  h1 {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    span {
      color: #7848f4;
    }
  }

  p {
    font-size: 17px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }
  h5 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }
}

// projects

.projects {
  h1 {
    font-size: 32px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;

    span {
      color: #7848f4;
    }
  }
  h4,
  h6 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }
  .para {
    font-size: 19px;
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }
  p {
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  .container {
    .box-1 {
      border: 1px solid #7848f4;
      min-height: 300px;
      border-radius: 15px 0px 0px 15px;

      .projectimg {
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
      }
    }

    .box-2 {
      min-height: 300px;
      background-color: #7848f4;
      border-radius: 0px 15px 15px 0px;

      .learn-more {
        text-decoration: none;
        background-color: #ffffff;
        color: #7848f4;
        border-radius: 20px;
        font-size: 15px;
        font-weight: 700;
        font-family: $font-text;
        letter-spacing: $text-letter-spacing;
        line-height: $text-line-height;
      }

      p {
        font-size: 15px;
      }
    }
  }
}

// internship-program-section end

// Media query ------------------------
// desktop view -----------------------
@media (max-width: 991px) {
  .gap-0 {
    gap: 0;
  }

  .gap-1 {
    gap: 16px;
  }

  .gap-2 {
    gap: 32px;
  }

  .gap-3 {
    gap: 48px;
  }

  .gap-4 {
    gap: 64px;
  }

  .gap-5 {
    gap: 80px;
  }

  // Story section for desktop
  .story-section {
    padding-left: 0;

    .story-left {
      padding: 0 $padding-x;

      p {
        line-height: 40px;
        font-size: 19px;
        margin-top: 20px;
      }

      img {
        width: 100px;
      }
    }

    .story-right {
      position: static;
      padding: $padding-x;
      margin-top: $padding-y;

      .story-container {
        position: static;
        width: 100%;

        .container {
          margin: 0;
          max-width: 100%;
        }
      }
    }
  }

  // Team Section for desktop
  .team-section {
    .team-left {
      justify-content: start;
      grid-template-columns: auto auto auto;
    }
  }
}

// tablet view ------------------------
@media (max-width: 767px) {
  .gap-0 {
    gap: 0;
  }

  .gap-1 {
    gap: 16px;
  }

  .gap-2 {
    gap: 32px;
  }

  .gap-3 {
    gap: 48px;
  }

  .gap-4 {
    gap: 64px;
  }

  .gap-5 {
    gap: 80px;
  }

  $fs-body: 16px;
  $fs-heading: 40px;
  $fs-btn: 16px;

  // common css for tablet
  p {
    font-size: $fs-body;
  }

  .community-heading {
    font-size: $fs-heading;
  }

  .btn {
    font-size: $fs-btn;
    padding: 7px 20px;
  }

  // hero section for tablet
  .hero-section {
    height: 100vh;

    h1 {
      font-size: 40px;
    }

    > p {
      font-size: 19.2px;
    }
  }

  // quote section for tablet
  .quote-section {
    .quote {
      box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.25);

      p {
        font-size: 24px;
      }
    }

    .video {
      bottom: 120px;

      iframe {
        width: 400px;
        height: auto;
      }
    }
  }

  // About section for tablet
  .about-section {
    .about-container {
      background: linear-gradient(
        0deg,
        #7948f4 41.85%,
        rgba(231, 154, 250, 0) 134.76%
      );

      .about-right {
        // padding: 3%;
        @include flex($justify: space-between, $direction: column);

        .about-card {
          @include flex(flex-start, start);
          text-align: left;
          background-color: white;
          padding: 20px;

          p {
            font-size: 12px;
          }

          img {
            width: 15px;
            // margin-top: 5px;
          }

          h3 {
            font-size: 14px;
          }
        }
      }
    }
  }

  // Join community section for tablet
  .join-community-section {
    padding-top: $padding-y + 10%;

    img {
      max-width: 100px;
    }

    .img-middle {
      bottom: 25px;
    }

    .icon {
      left: -30px;
      width: 80px;
    }
  }

  // Volunteer section for tablet
  .volunteer-section {
    margin: 20% 0;
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
  }

  // Contributor Section for tablet
  .contributor-section {
    h1 {
      font-size: 32px;
    }
    .contributor-container {
      margin-top: 40px;

      .contributor-img {
        width: 150px;
      }
      h5 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
      }
    }
  }

  // Contribute section for tablet
  .contribute-section {
    margin: 15% 0;
  }

  // Team Section for tablet
  .team-section {
    margin: 18% 0;

    .team-left {
      gap: 15px;

      .team-img {
        max-width: 120px;
      }
    }
  }

  // Community Partners Section for tablet
  .community-partner-section {
    .community-partner-container {
      img {
        width: 100px;
      }
    }
  }
  .footer {
    h1 {
      font-size: 32px;
    }
    h6 {
      font-size: 16px;
    }
  }
}

// Mobile view ----------------------------
@media (max-width: 480px) {
  $fs-body: 12.8px;
  $fs-heading: 28.8px;
  $fs-btn: 14.4px;

  .gap-0 {
    gap: 0;
  }

  .gap-1 {
    gap: 16px;
  }

  .gap-2 {
    gap: 32px;
  }

  .gap-3 {
    gap: 48px;
  }

  .gap-4 {
    gap: 64px;
  }

  .gap-5 {
    gap: 80px;
  }

  // common css for mobile
  p {
    font-size: $fs-body;
  }

  .community-heading {
    font-size: $fs-heading;
  }

  .btn {
    font-size: $fs-btn;
  }

  // hero section for mobile
  .hero-section {
    height: auto;
    padding: 10px 0;

    h1 {
      font-size: 30px !important;
      margin-top: 0;
    }

    p {
      font-size: 16px;
    }

    .engaging-text {
      font-size: 20px;
      position: static;
      bottom: -40px;
    }
  }

  // Counter section for mobile
  .counter-section {
    h1 {
      font-size: 80px;
    }

    span {
      font-size: 40px;
    }
  }

  // quote section for mobile
  .quote-section {
    .quote {
      p {
        font-size: 20px;
      }

      > div {
        height: 80px;
      }
    }

    .video {
      bottom: 90px;

      iframe {
        width: 90%;
      }
    }
  }

  .about-section {
    h1 {
      font-size: 32px;
      font-weight: 700;
    }
    .about-container {
      .about-right {
        h3 {
          font-size: 18px !important;
        }
        p {
          font-size: 16px !important;
        }
      }
    }
  }

  // Join community section for mobile
  .join-community-section {
    padding-top: $padding-y + 15%;

    img {
      max-width: 72px;
    }
    h1 {
      font-size: 32px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      text-align: center;
    }
  }

  // Event Section for mobile
  .event-section {
    padding-top: $padding-y;
    h1 {
      font-weight: 700;
      font-size: 32px;
    }
    .community-event-card {
      padding: 18px;
      margin-top: 20px;

      p {
        font-size: 12px;
      }
    }

    .event-right {
      img {
        width: 80%;
      }
    }
    .text-left {
      h6 {
        font-size: 18px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .gallery-section {
    background-color: #9e7bf7;
    border-radius: 5px;
    padding: 40px;
    box-shadow: inset 0 0 70px rgba(255, 255, 255, 1);

    .image-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;

      img {
        width: 80px;
      }
    }

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 15px;
    }

    button {
    }
  }

  // Contributor Section for mobile
  .contributor-section {
    .contributor-container {
      gap: 20px;

      .contributor-img {
        width: 110px;
      }
    }
  }

  // Contribute section for mobile
  .contribute-section {
    margin: 20% 0;
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
    img {
      width: 90%;
    }
    .plane-img {
      right: 70px;
    }
  }

  // Story section for mobile
  .story-section {
    .story-left {
      img {
        width: 70px;
      }
    }

    .story-right {
      padding-left: 0;

      .story-container {
        position: static;
        width: 100%;

        .container {
          margin: 0;
          max-width: 100%;
        }
      }
    }
  }

  // Team Section for mobile
  .team-section {
    margin: 20% 0;

    .team-left {
      gap: 10px;

      .team-img {
        width: 100%;
      }
    }
    h3 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
  }

  // Community Program Section for mobile
  .community-program-section {
    .program-container {
      margin: 5% 0;
    }
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
  }

  //  Internship Program page
  .internship-hero-section {
    .title {
      font-size: 52px;
      text-align: center;
    }

    .para {
      font-size: 28px;
      text-align: center;
    }

    .heroimg {
      width: 100%;
      padding-top: 30px;
    }
  }

  // first step
  .firstStep {
    p {
      letter-spacing: 0px;
    }
  }

  .our-culture {
    .para {
      letter-spacing: 0px;
    }
  }

  // become professional
  .become-professional {
    .our-internship {
      .container {
        .icon {
          font-size: 37px;
        }
      }
    }
  }

  // projects

  .projects {
    .para {
      letter-spacing: 0px;
    }

    .container {
      .box-1 {
        border-radius: 15px 15px 0px 0px;

        .projectimg {
          border-radius: 10px 10px 10px 10px;
        }
      }

      .box-2 {
        border-radius: 0px 0px 15px 15px;
      }
    }
    .intern-carousel {
      display: flex;
      // justify-content: center;
    }
  }

  // life here

  .life-here {
    p {
      letter-spacing: 0px;
    }

    .story-right {
      min-height: 300px;

      .story-container {
        width: 100%;

        .story-item {
          img {
            height: 200px;
            width: 280px;
          }
        }
      }
    }
  }

  // hiring partner
  .hiring-partners {
    p {
      letter-spacing: 0px;
    }
  }

  // events
  .events {
    p {
      letter-spacing: 0px;
    }

    .peopleimg {
      width: 100%;
    }

    .head {
      text-align: center;
    }
  }

  // join-us
  .join-us {
    p {
      letter-spacing: 0px;
    }
  }

  .point-container {
    grid-template-columns: 1fr !important;
    /* Switch to a single column on smaller screens */
  }

  .Our-img-Work {
    .card {
      margin-top: 10px;
    }
  }
}
