@import "./_variable";
// @import "https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700";

// // Variables
// $bg-body: #f9f9f9;

// $red: #673de6;
// $blue: #2b2e48;
// $primary-color: $red;
// $secondary-color: $blue;

// // Typography
// $base-font: "Akzidenz", "Helvetica Neue", Helvetica, Arial, sans-serif;
// $base-font-title: "Akzidenz", "Helvetica Neue", Helvetica, Arial, sans-serif;

// $base-font-color: #726f77;

// // Timeline
// $timeline-color: $primary-color;

// // Mixins and Placeholders
// %clearfix {
//   &:after,
//   &:before {
//     content: "";
//     display: block;
//     width: 100%;
//     clear: both;
//   }
// }

// @mixin prefix($prop, $val) {
//   @each $prefix in "-webkit-", "-moz-", "-ms-", "" {
//     #{$prefix}#{$prop}: $val;
//   }
// }
// *,
// *:before,
// *:after {
//   box-sizing: border-box;
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
// }

// // body,
// // html {
// //   height: 100%;
// // }
// // body {
// //   background: $bg-body;
// //   background-size: cover;
// //   margin: 0;
// //   padding: 0;
// //   font-family: $base-font;
// //   line-height: 20px;
// //   font-size: 14px;
// //   color: $base-font-color;
// // }

// // img {
// //   max-width: 100%;
// // }

// a {
//   text-decoration: none;
// }

// .container {
//   max-width: 800px;
//   margin: 0 auto;
// }

// // h1,
// // h2,
// // h3,
// // h4 {
// //   font: {
// //     family: $base-font-title;
// //     weight: 500;
// //   }
// // }

// .project-name {
//   text-align: center;
//   padding: 10px 0;
// }

// // Header
// header {
//   background: $secondary-color;
//   padding: 10px;
//   @include prefix(box-shadow, 0 3px 3px rgba(0, 0, 0, 0.05));
//   @extend %clearfix;

//   .logo {
//     color: $primary-color;
//     float: left;
//     font: {
//       family: $base-font-title;
//       size: 22px;
//       weight: 500;
//     }

//     > span {
//       font-weight: 300;
//     }
//   }

//   .social {
//     float: right;
//     .btn {
//       font-family: "Dosis";
//       font-size: 14px;
//       margin: 10px 5px;
//     }
//   }
// }

// // Timeline
// #timeline {
//   width: 100%;
//   margin: 30px auto;
//   position: relative;
//   padding: 0 10px;
//   @include prefix(transition, all 0.4s ease);

//   &:before {
//     content: "";
//     width: 5px;
//     height: 100%;
//     background: $timeline-color;
//     left: 50%;
//     top: 0;
//     position: absolute;
//   }

//   &:after {
//     content: "";
//     clear: both;
//     display: table;
//     width: 100%;
//   }

//   .timeline-item {
//     margin-bottom: 50px;
//     position: relative;
//     @extend %clearfix;

//     .timeline-icon {
//       background: $timeline-color;
//       width: 50px;
//       height: 50px;
//       position: absolute;
//       top: 0;
//       left: 50%;
//       overflow: hidden;
//       margin-left: -23px;
//       @include prefix(border-radius, 50%);

//       svg {
//         position: relative;
//         top: 14px;
//         left: 14px;
//       }
//     }

//     .timeline-content {
//       width: 30%;
//       background: #fff;
//       padding: 20px;
//       @include prefix(box-shadow, 0 3px 0 rgba(0, 0, 0, 0.1));
//       @include prefix(border-radius, 5px);
//       @include prefix(transition, all 0.3s ease);

//       h2 {
//         padding: 14px;
//         font-size: 25px;
//         background: $timeline-color;
//         color: #fff;
//         margin: -20px -20px 0 -20px;
//         font-weight: 500;
//         @include prefix(border-radius, 5px 5px 0 0);
//       }

//       p {
//         margin-top: 10px;
//         font-size: 12px;
//       }

//       &:before {
//         content: "";
//         position: absolute;
//         left: 45%;
//         top: 20px;
//         width: 0;
//         height: 0;
//         border-top: 7px solid transparent;
//         border-bottom: 7px solid transparent;
//         border-left: 7px solid $timeline-color;
//       }

//       &.right {
//         float: right;

//         &:before {
//           content: "";
//           right: 45%;
//           left: inherit;
//           border-left: 0;
//           border-right: 7px solid $timeline-color;
//         }
//       }
//     }
//     .timeline-img {
//       width: 30%;
//       background: #fff;
//       padding: 20px;
//       top: -300px;
//       @include prefix(border-radius, 5px);
//       @include prefix(transition, all 0.3s ease);

//       h2 {
//         padding: 14px;
//         font-size: 25px;
//         background: $timeline-color;
//         color: #fff;
//         margin: -20px -20px 0 -20px;
//         font-weight: 500;
//         @include prefix(border-radius, 5px 5px 0 0);
//       }

//       p {
//         margin-top: 10px;
//         font-size: 12px;
//       }

//       &:before {
//         content: "";
//         position: absolute;
//         left: 45%;
//         width: 0;
//         height: 0;
//         border-top: 7px solid transparent;
//         border-bottom: 7px solid transparent;
//         border-left: 7px solid $timeline-color;
//       }

//       &.right {
//         float: right;

//         &:before {
//           content: "";
//           right: 45%;
//           left: inherit;
//           border-left: 0;
//           border-right: 7px solid $timeline-color;
//         }
//       }
//     }
//   }
// }

// // // Buttons
// // .btn {
// //   padding: 5px 15px;
// //   text-decoration: none;
// //   background: transparent;
// //   border: 2px solid lighten($primary-color, 10%);
// //   color: lighten($primary-color, 10%);
// //   display: inline-block;
// //   position: relative;
// //   text-transform: uppercase;
// //   font-size: 12px;
// //   @include prefix(border-radius, 5px);
// //   @include prefix(transition, background 0.3s ease);
// //   @include prefix(box-shadow, 2px 2px 0 lighten($primary-color, 10%));

// //   &:hover {
// //     box-shadow: none;
// //     top: 2px;
// //     left: 2px;
// //     @include prefix(box-shadow, 2px 2px 0 transparent);
// //   }
// // }

// @media screen and (max-width: 767px) {
//   #timeline {
//     margin: 30px;
//     padding: 0px;
//     width: 90%;
//     &:before {
//       left: 0;
//     }

//     .timeline-item {
//       .timeline-content {
//         width: 90%;
//         float: right;

//         &:before,
//         &.right:before {
//           left: 10%;
//           margin-left: -6px;
//           border-left: 0;
//           border-right: 7px solid $timeline-color;
//         }

//         h2 {
//           font-size: 15px;
//         }
//       }

//       .timeline-icon {
//         left: 0;
//       }
//     }
//   }
// }

// --------------------------------------------------------------

$container-shadow: 0.5rem 0.5rem 2rem 0 rgba(black, 0.2);

$gutter: 30px;
$border-width: 3px;
$dot-diameter: 23px;
$dot-diameter-mobile: 28px;

.timeline-body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  width: 90%;
  padding: 5vh 100px;
  font-family: "Source Sans Pro", arial, sans-serif;
  font-weight: 300;
  color: #333;
}

.timeline {
  width: 100%;
  background: #fff;
  padding: 80px 50px;
  position: relative;

  // box-shadow: $container-shadow;
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: calc(33% + 15px); //$gutter/2
    bottom: 0px;
    width: $border-width;
    background: #ddd;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.entry {
  clear: both;
  text-align: left;
  position: relative;

  .title {
    margin-bottom: 2em;
    float: left;
    width: 33%;
    // padding-right: $gutter;
    text-align: center;
    position: relative;

    .img {
      position: relative;
      top: -60px;
      border-radius: 60% !important;
    }

    &:before {
      content: "";
      position: absolute;
      width: $dot-diameter;
      height: $dot-diameter;
      border: $border-width solid $light-voilet;
      background-color: #fff;
      border-radius: 100%;
      top: 0;
      right: -11px;
      z-index: 99;
    }

    h3 {
      margin: 0;
      font-size: 120%;
      font-weight: 500;
      color: $heading-text !important;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }

  .body {
    margin: 0 0 3em;
    float: right;
    width: 66%;
    padding-left: $gutter;
    padding-right: 40px;

    p {
      line-height: 1.4em;

      &:first-child {
        margin-top: 0;
        font-weight: 400;
        font-size: 13px;
      }
    }

    h3 {
      font-size: 120%;
      font-weight: 500;
      color: $heading-text;
      margin-bottom: 10px;
    }

    ul {
      li {
        font-size: 14px;
      }
    }
  }
}

.timeline-dayscode-body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  // width: 80%;
  padding: 5vh 100px;
  font-family: "Source Sans Pro", arial, sans-serif;
  font-weight: 300;
  color: #333;
}

.timeline-dayscode {
  width: 100%;
  background: #fff;
  padding: 80px 50px;
  position: relative;

  // box-shadow: $container-shadow;
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: calc(33% + 15px); //$gutter/2
    bottom: 0px;
    width: $border-width;
    background: #ddd;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.entry-days {
  clear: both;
  text-align: left;
  position: relative;

  .title {
    margin-bottom: 2em;
    float: left;
    width: 33%;
    // padding-right: $gutter;
    text-align: center;
    position: relative;
    align-items: end;

    .img {
      position: relative;
      top: -60px;
      border-radius: 60% !important;
    }

    &:before {
      content: "";
      position: absolute;
      width: $dot-diameter;
      height: $dot-diameter;
      border: $border-width solid $dark-green;
      background-color: #fff;
      border-radius: 100%;
      top: 0;
      right: -11px;
      z-index: 99;
    }

    h3 {
      margin: 0;
      font-size: 120%;
      font-weight: 500;
      color: $heading-text !important;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }

  .body {
    margin: 0 0 3em;
    float: right;
    width: 63%;
    padding: 15px;

    p {
      line-height: 1.4em;

      &:first-child {
        margin-top: 0;
        font-weight: 400;
        font-size: 13px;
      }
    }

    h3 {
      font-size: 120%;
      font-weight: 500;
      color: $heading-text;
      margin-bottom: 10px;
    }

    ul {
      li {
        font-size: 14px;
      }
    }
  }
}

//  grow-learning-community
.grow-learning-community {
  .nav-item {
    cursor: pointer;
  }

  .grow-community-img-section {
    height: 80px;
    width: 80px;
  }
  .bg-6C77C1{
    background-color: #6C77C1;
  }
  .border-6C77C1{
    border: 2px solid #6C77C1;
  }

  .nav-item.active {
    border-bottom: 4px solid #007bff;
    margin: 0px;
  }
}

//  Grow
.learning-grow-carousel {
  .grow-card {
    h5 {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 2px;
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
    }

    p {
      font-size: 20px;
      font-weight: 300;
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }

    .grow-img-section {
      height: 210px;
      width: 230px;
    }
    button {
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
  }
}

@media (max-width: 767px) {
  .flow-title {
    font-size: 20px;
    padding: 0 15px;
  }

  .timeline-body {
    margin: 50px auto;
    width: 100%;
    padding: 0;
  }

  .timeline-dayscode {
    padding: 2px;

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: calc(4% + 5px); //$gutter/2
      bottom: 0px;
      width: $border-width;
      background: #ddd;
    }

    .entry-days {
      &:before {
        display: none;
      }

      .title {
        margin: 0;
        align-items: center;

        &:before {
          content: "";
          position: absolute;
          width: $dot-diameter;
          height: $dot-diameter;
          border: $border-width solid $dark-green;
          background-color: #fff;
          border-radius: 100%;
          top: 0;
          right: 70%;
          z-index: 99;
        }
      }

      .body {
        margin: 0 0 3em;
        width: 86%;

        h3 {
          margin-bottom: 10px;
          font-size: 90%;
          color: $heading-text;
        }

        p {
          font-size: 14px;
        }

        ul {
          li {
            font-size: 13px;
          }
        }
      }
    }

    .entry .title:before {
      display: none;
    }
  }

  .timeline-dayscode-body {
    padding: 0;
    margin: 50px auto;
    width: 100%;
  }

  .timeline {
    padding: 2px;
    box-shadow: none;

    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: calc(12% + 15px); //$gutter/2
      bottom: 0px;
      width: $border-width;
      background: #ddd;
    }

    .entry {
      &:before {
        display: none;
      }

      .title {
        padding-right: 0;

        &:before {
          display: none;
        }

        .img {
          position: relative;
          top: 0px;
        }
      }

      .body {
        margin: 0 0 2em;
        padding: 0 15px 0 0px;

        h3 {
          margin-bottom: 10px;
          font-size: 90%;
          color: $heading-text;
        }

        p {
          font-size: 14px;
        }

        ul {
          li {
            font-size: 13px;
          }
        }
      }
    }

    .entry .title:before {
      border: $border-width solid $heading-text;
      background-color: #fff;
      border-radius: 100%;
      top: 12%;
      right: -$dot-diameter-mobile;
      z-index: 99;
    }
  }
}

@media (max-width: 480px) {
  .grow-learning-community {
    .grow-community-img-section {
      height: 40px;
      width: 40px;
    }

    h6 {
      font-size: 10px;
    }

    .nav-item.active {
      border-bottom: 2px solid #007bff;
      margin: 0px;
    }
  }

  //  Grow
  .learning-grow-carousel {
    .grow-card {
      h5 {
        font-size: 24px;
        font-weight: 900;
        letter-spacing: 2px;
      }

      p {
        font-size: 14px;
        font-weight: 300;
        text-align: justify;
      }
    }

    .grow-img-section {
      height: 200px;
      width: 200px;
    }
  }
}
