@import './_variable';

.sagethon-container {
  background-color: $background !important;
  color: #fff !important;
  font-family: $sagethon-family !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 40;
  font-variation-settings: "slnt" 0;
}

.sagethon-heading-text {
  user-select: none;
  font-family: $sagethon-family;
  font-size: 3.75rem;
  font-weight: 200;
  line-height: 1.375;
  color: $text-color;
  opacity: 0.8;
}

.text-lines {
  font-family: $sagethon-family !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  line-height: 180% !important;
  opacity: 0.8;
  color: $text-color !important;
}

.sagethon-body-text {
  font-size: 1.25rem;
  font-family:
    Public Sans,
    Inter,
    sans-serif;
  font-weight: 300;
  color: $text-color !important;
}
.track-card {
  background-color: $card-background;
  border-radius: 18px;
  border: solid 0.5px #4a4a4a;
  box-shadow: #4a4a4a;
}

.track-title {
  user-select: none;
  font-family:
    Config Rounded,
    Rubik,
    sans-serif;
  font-size: 1.15rem;
  font-weight: 500;
  color: $text-color;
  opacity: 1;
  text-transform: uppercase;
}

.track-description {
  font-family:
    Public Sans,
    Inter,
    sans-serif;
  font-size: 0.95rem;
  font-weight: 300;
  line-height: 170%;
  color: $text-color !important;
}

.sagethon-hero-section {
}

.sagethon-everyone-welcome-section {
}

.sagethon-statistics-number {
  font-size: 100px !important;
  color: $hover-text-color !important;
  font-weight: 100 !important;
}

.sagethon-faq-section {
}

.sagethon-faq-card {
  background-color: $card-background;
  border-radius: 10px;
  border: solid 0.5px #4a4a4a;
  box-shadow: #4a4a4a;
  &:hover {
    .text-lines {
      color: $hover-text-color !important;
    }
    background-color: hsla(0, 0%, 100%, 0.09);
  }
}
