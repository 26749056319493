@import './_variable';
.index-technologies {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%) !important;

  .nav-link {
    font-size: 14px;
    a {
      color: #74747d !important;
      &:hover {
        color: #673de6 !important;
        text-decoration: none !important;
      }
    }
  }
  .heading-1{
    font-size: 50px;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }
  .text{
    font-size: 14px;
    font-family: $font-headings;
    line-height: $heading-line-height;
    letter-spacing: $text-letter-spacing;
  }
}
