@import "./_variable";

.AiSummit {
  background-color: #151931;
  color: $fontColorHeading !important;

  .aisummit-header-bg {
    background-image: linear-gradient(
        rgba(128, 0, 128, 0.5),
        rgb(6 3 143 / 50%)
      ),
      url("http://res.cloudinary.com/datacode/image/upload/v1733497087/hkw5pqo1oikbp8zyi5gk.jpg");
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
    /* Replace 'Roboto' with your desired font */
  }

  p {
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
  }

  .ai-summit-header {
    .ai-summit-header-img {
      height: 350px;
    }

    .ai-summit-header-contain {
      p {
        font-size: 20px;
        letter-spacing: 1px;
      }
    }
  }

  //AiSummitJoinCard
  .AiSummitJoinCard {
    .AiSummitJoinCard-A {
      width: 450px;

      p {
        font-size: $PeraGraphFontSize;
      }
    }
  }

  //AiSummitCardBox
  .AiSummitCardBox {
    .AiSummitCardBox-Card {
      border-radius: 10px;
      width: 260px;
      height: 200px;
      background-color: rgba(32, 44, 65, 0.826);
      transition: all ease-in-out;

      &:hover {
        background-color: rgba(97, 219, 251, 0.2) !important;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5),
          0px 10px 40px rgba(97, 219, 251, 0.3);
      }

      h3 {
        font-size: 40px;
        color: $fontColorHeading;
      }

      h5 {
        font-size: 20px;
        color: $fontColorHeading;
      }

      p {
        font-size: 15px;
        color: $fontColorHeading;
      }
    }
  }

  // AiSummitSponsor
  .AiSummitSponsor {
    color: $fontColorHeading !important;

    .AiSummitSponsor-col-A {
      span {
        font-size: 35px;
      }

      h3 {
        font-size: 40px;
        color: $fontColorHeading !important;

        &:hover {
          text-decoration: underline;
        }
      }

      p {
        color: $fontColorHeading !important;
        font-size: 18px;
      }

      .AiSummitSponsor-col-A-Button {
        background-color: rgba(97, 219, 251, 0.9);

        span {
          font-size: 20px !important;
        }

        font-size: 15px;
        color: black !important;
      }
    }
  }

  .participants-carosuel {
    .transform-hover {
      width: 200px;
      height: 200px;
      animation-play-state: paused;
    }

    .transform-hover:hover {
      transition: transform 0.3s ease-in-out;
      border-radius: 0.3rem;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.75);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    .overlay:hover {
      opacity: 1;
    }

    .transform-hover:hover .overlay {
      opacity: 1;
    }

    .custom-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .custom-frame {
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    .scroll-left {
      animation: scroll-left 260s linear infinite;
    }

    .scroll-right {
      animation: scroll-right 260s linear infinite;
    }

    @keyframes scroll-left {
      0% {
        transform: translateX(0%);
      }

      100% {
        transform: translateX(-2500%);
      }
    }

    @keyframes scroll-right {
      0% {
        transform: translateX(-2500%);
      }

      100% {
        transform: translateX(00%);
      }
    }

    .paused {
      animation-play-state: paused;
    }
  }
}

//aisummit-rspv-form
.aisummit-rspv-form {
  text-align: left !important;
  display: flex;
  justify-content: center;

  h1 {
    font-size: 28px;
    color: $fontColorHeading !important;
    text-align: center;
    font-weight: 500;
  }

  .form-content {
    background: linear-gradient(rgba(168, 85, 247, 0.3), rgba(72, 45, 98, 0.3));
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  }

  button {
    background-color: white !important;
    color: #7848f4;

    &:hover {
      color: #fff !important;
      background-color: #5a6268 !important;
      border-color: #545b62 !important;
    }

    &:disabled {
      color: #7848f4;
    }
  }

  label {
    text-align: left !important;
    padding: 5px 10px 0 10px !important;
  }

  input {
    background-color: #060b21;
    color: white;
    border-color: #090e21;
    padding: 20px;
    width: 350px;

    &:focus {
      background-color: #060b21;
      color: white;
    }
  }

  .error-container {
    align-items: center;
    text-align: center;

    .error {
      text-align: center;
      color: red;
      background-color: #cfcfcf;
      padding: 10px;
      border-radius: 10px;
      margin: 10px;
      display: flex;
    }
  }

  .input-label {
    width: 100%;
    color: #fff;
    font-size: 13px;
  }

  .input-label-rsvp-status {
    font-size: 18px;
    padding-top: 0 !important;
  }

  .input-feild {
    width: 100%;
    color: rgba(255, 255, 255, 0.514);
    font-size: 15px;
    border-color: #090e21;
    border-radius: 5px;
    font-weight: 400;
    letter-spacing: 1.1px;
  }

  .ant-steps-item-title {
    color: #fff !important;

    &:after {
      background-color: #fff !important;
    }
  }

  .ant-steps-item-description {
    color: #ffffff86 !important;
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      span {
        color: #545b62 !important;
      }

      background-color: #fff;
    }
  }

  .frame-section {
    justify-content: center !important;
    background-color: transparent;
    align-items: center !important;

    // flex-direction: column;
    .frame-download {
      margin: 0 auto;
      width: 400px;
      height: 400px;
    }

    .ai-digital-badge {
      h1 {
        font-size: 20px !important;
      }
    }

    h1 {
      font-size: 35px !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    p {
      text-align: center;
      color: #fff;
      margin: 10px 0 !important;

      .hashtag {
        color: #fff !important;
      }
    }

    .form-elements {
      // display: none !important;
      margin: 25px auto !important;
      flex-direction: column !important;
    }

    .caption-layout {
      display: none !important;
    }

    #downloadBtn {
      color: #7848f4;

      &:hover {
        background-color: #7848f4 !important;
        color: #fff !important;
      }
    }

    .submit-button {
      background-color: #7848f4 !important;

      &:hover {
        background-color: #fff !important;
        color: #7848f4 !important;
      }
    }
  }

  .successfull-rsvp {
    background-color: #535186;
    color: rgb(11, 253, 11);
    height: 70px;
    width: 300px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error-rsvp {
    background-color: #86517f73;
    height: 70px;
    width: 300px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(253, 11, 11);
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 24px;
      text-align: left;
    }

    input {
      padding: 25px !important;
      width: 300px;
    }

    .ant-steps-item-tail {
      &:after {
        background-color: #fff !important;
      }
    }

    .frame-download {
      height: 340px !important;
      width: 340px !important;
    }

    .form-control {
      .rsvp-heading {
        font-size: 28px;
      }
    }
  }

  @media (max-width: 400px) {
    .frame-download {
      height: 320px !important;
      width: 320px !important;
    }
  }

  @media (max-width: 380px) {
    .frame-download {
      height: 300px !important;
      width: 300px !important;
    }
  }
}

// AiSummitCounter
.AiSummitCounter {
  .AiSummitCounter-section {
    border-radius: 10px;
    align-items: center;

    background: linear-gradient(rgba(168, 85, 247, 0.3), rgba(72, 45, 98, 0.3));

    @keyframes pulse {
      0% {
        background: linear-gradient(
          rgba(168, 85, 247, 0.3),
          rgba(72, 45, 98, 0.3)
        );
      }

      50% {
        background: linear-gradient(
          rgba(168, 85, 247, 0.5),
          rgba(72, 45, 98, 0.3)
        );
      }

      100% {
        background: linear-gradient(
          rgba(168, 85, 247, 0.3),
          rgba(72, 45, 98, 0.3)
        );
      }
    }

    /* Apply the smooth pulse animation */
    animation: pulse 6s ease-in-out infinite;
  }

  .text-24 {
    font-size: 24px;
    color: $fontColorHeading !important;
  }

  .text-20 {
    font-size: 20px;
    color: $fontColorHeading !important;
  }

  h1 {
    color: $fontColorHeading !important;
  }
}

//AiSummitOurPartners
.AiSummitOurPartners {
  h1 {
    color: $fontColorHeading !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

//aisummit-ourcommunity-partner
.aisummit-ourcommunity-partner {
  h1 {
    color: $fontColorHeading !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

//aisummit-our-sponsor
.aisummit-our-sponsor {
  h1 {
    font-size: $HeadingFontSize;
    color: $fontColorHeading !important;
  }

  &:hover {
    text-decoration: underline;
  }
}

//AiSummitOurOrganizer
.AiSummitOurOrganizer {
  h4 {
    color: $fontColorHeading !important;
  }
}

//AiSummitOurSpeaker
.AiSummitOurSpeaker {
  h1 {
    font-size: $HeadingFontSize;
    color: $fontColorHeading !important;

    &:hover {
      text-decoration: underline;
    }
  }

  .ai-summit-ourspeaker-card {
    h5 {
      color: rgba(97, 219, 251, 0.9);
    }

    p {
      font-size: 15px;
      color: $fontColorHeading !important;
    }

    .img-section {
      .img-A {
        border-radius: 10%;
        border: 4px solid #151931;
        outline: 6px solid rgba(97, 219, 251, 0.9);
      }
    }
  }
}

//AiSummitOurSpeakerOrganizers
.AiSummitOurSpeakerOrganizers {
  h1 {
    font-size: $HeadingFontSize;
    color: $fontColorHeading !important;

    &:hover {
      text-decoration: underline;
    }
  }

  .ai-summit-ourspeaker-card {
    h5 {
      color: rgba(97, 219, 251, 0.9);
      font-weight: 400 !important;
    }

    p {
      font-size: 14px;
      color: $fontColorHeading;
    }

    .img-section {
      .img-A {
        border-radius: 10%;
        border: 4px solid #151931;
        outline: 6px solid rgba(97, 219, 251, 0.9);
      }
    }
  }
}

// ai-summit-speakers-words
.ai-summit-speakers-words {
  h1 {
    font-size: $HeadingFontSize;
    color: $fontColorHeading !important;

    &:hover {
      text-decoration: underline;
    }
  }

  .ai-summit-ourspeaker-card {
    img {
      // max-height: 400px;
      // border-radius: 20px;
      object-fit: cover;
    }

    p {
      color: #fff !important;
    }
  }
}
//ai-summit-event-glimpse
.ai-summit-event-glimpse {
  .side-images {
    height: 140px !important;
    object-fit: cover;
  }

  .main-image {
    object-fit: cover;
    height: 460px;
  }

  .custom-bottom-margin {
    padding-bottom: 12px;
  }

  .react-multi-carousel-list {
    padding: 0px !important;

    .react-multi-carousel-item {
      width: 300px;
      padding: 0 0px 0 0px !important;
    }
  }
}

// aisummit-schedule
.aisummit-schedule {
  h1 {
    font-size: $HeadingFontSize;
    color: $fontColorHeading;

    &:hover {
      text-decoration: underline;
    }

    border-radius: 10px;
  }

  .aisummit-schedule-row-B_1 {
    border: 1px solid black;
    border-radius: 10px;
    background-color: rgba(97, 219, 251, 0.9);
  }

  .aisummit-schedule-Card {
    background-color: #151931;
    border: 1px solid white;
    transition: ease-in 0.3s;
    border-radius: 10px;

    &:hover {
      background-color: rgb(36, 27, 53);
    }

    h6 {
      color: $fontColorHeading;
      font-size: 18px;
    }

    .aisummit-schedule-Card-head {
      h4 {
        color: white;
      }

      p {
        color: rgb(156 163 175);
      }
    }
  }
}

// AiSummitVenue
.AiSummitVenue {
  h1 {
    font-size: $HeadingFontSize;
    color: white !important;

    &:hover {
      text-decoration: underline;
    }

    border-radius: 10px;
  }

  h4 {
    color: $fontColorHeading;

    &:hover {
      text-decoration: underline;
    }

    border-radius: 10px;
  }

  p {
    font-size: 20px;
    color: $fontColorHeading;
  }
}

.email-form-control {
  width: 100%;
  height: 15vh;
  border-radius: 8px;
  border: 1px solid #545b62;
  font-size: 20px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.514);
    font-weight: 400;
  }
}

.aisummit-feedback-card {
  color: #fff !important;
  padding: 10px;

  h1 {
    color: #d4d4d4 !important;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.1px;
    font-family: $font-headings;
  }

  p {
    color: #c9c9c9 !important;
    font-size: 16px;
    font-weight: 300 !important;
    font-family: $font-text;
  }
}

//Mobile Responsive

@media (max-width: 480px) {
  //AiSummitHeder

  .ai-summit-header {
    .ai-summit-header-img {
      height: 108px !important;
    }

    .ai-summit-header-contain {
      p {
        font-size: 16px !important;
        letter-spacing: 0.5px;
        text-align: justify;
      }
    }
  }

  //aisummit-ourcommunity-partner
  .aisummit-ourcommunity-partner {
    h1 {
      font-size: 30px;
    }
  }

  //AiSummitCardBox
  .AiSummitCardBox {
    .AiSummitCardBox-Card {
      width: 296px !important;
      height: 200px;

      h3 {
        font-size: 40px;
      }

      h5 {
        font-size: 20px;
      }

      p {
        font-size: 15px;
      }
    }
  }

  //aisummit-our-sponsor
  .aisummit-our-sponsor {
    h1 {
      font-size: 30px;
      font-weight: 400 !important;
    }
  }

  //AiSummitOurSpeaker
  .AiSummitOurSpeaker {
    h1 {
      font-size: 30px;
      font-weight: 400 !important;
    }

    .ai-summit-ourspeaker-card {
      .img-section {
        justify-content: center !important;
      }
    }
  }

  // AiSummitCounter
  .AiSummitCounter {
    .text-24 {
      font-size: 18px;
      color: $fontColorHeading !important;
    }

    .text-20 {
      font-size: 15px;
      color: $fontColorHeading !important;
    }
  }

  // aisummit-schedule
  .aisummit-schedule {
    h1 {
      font-size: 30px;
      font-weight: 400 !important;
    }
  }
}

//ipad mini
@media (min-width: 768px) {
  .ai-summit-header {
    .ai-summit-header-img {
      height: 180px !important;
    }
  }
}
