@import './_variable';

.header-profile-img {
  border: solid 1px #726f77;
  object-fit: contain;
}
.header-profile {
  align-items: center;
}

.edit-profile-btn {
  width: 200px;
  border: solid 1px $base-font-color;
  font-size: 14px;
  border-radius: 7px;
  &:hover {
    background-color: $light-voilet;
    color: #ffffff;
  }
}

.upload-img-btn {
  width: 200px;
  background-color: #30006d;
  color: #ffffff;
  font-size: 14px;
  :hover {
    color: #ffffff !important;
  }
}

.left-profile-section {
  align-items: center;
  justify-content: center;
  display: inline;
  box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
  border-radius: 5px;
}

.bio {
  font-size: 12px;
  color: $heading-text;
}

.edit-submit-btn {
  width: 100px;
  height: 50px;
  border-radius: 7px;
  background-color: #30006d;
  color: #ffffff;
  :hover {
    background-color: white;
  }
}

.graduation-details {
  i {
    margin-right: 15px;
  }
  h6 {
    margin-top: 20px;
    font-weight: 300;
  }
  border: none;
  box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
  height: auto;
  border-radius: 5px;
}

.profile-dashboard {
  i {
    font-size: 15px;
    margin-right: 10px;
  }
  span {
    font-size: 15px;
    color: #726f77;
  }
}
