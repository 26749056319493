$section-bg: #f9f9fd;
$light-voilet: #7968ce;
$paratext: #676767;
$content-color: #8082a5;
$btn-bg: #7848f4;
$fs-heading: 40px;
$fs-body: 18px;
$gap-x: 5%;
$gap-y: 7%;
$fontFamily: "Montserrat";

@import "./_variable";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

.padding-x {
  padding-left: $gap-x;
  padding-right: $gap-x;
}

.padding-y {
  padding-top: $gap-y;
  padding-bottom: $gap-y;
}

.wittyhacks {
  .hero-section {
    img {
      width: 100%;
    }
  }

  //Details about wittyhack
  .details-panel {
    font-family: $fontFamily;

    span {
      color: #535151;
      font-size: 32px;
      font-weight: 300;
      display: flex;
      justify-content: center;
      line-height: 48px;
    }

    h1 {
      font-size: 96px;
      font-weight: 600;
      color: $black;
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;

      // line-height: 144px;
      @media screen and (max-width: 700px) {
        font-size: 50px;
      }
    }

    .gradient-line {
      width: 135px;
      height: 9px;
      background-image: linear-gradient(to right, #4a2dff, #961dcf, #ec0ca0);
      border-radius: 30px;
    }

    p {
      font-weight: 300px;
      font-size: 24px;
      line-height: 45px;
      width: 90%;
      margin: auto;
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;

      @media screen and (max-width: 450px) {
        font-size: 18px;
      }
    }

    .presented-by {
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
    //Datcode logo
    .logo-shadow {
      box-shadow: 2px 4px 10px 2px $black inset;
      margin: auto;
      border-radius: 26px;
      width: 660px;
      height: 250px;

      @media screen and (max-width: 700px) {
        width: 450px;
        height: 100px;
      }

      @media screen and (max-width: 480px) {
        width: 300px;
        height: 70px;
        border-radius: 10px;
      }

      img {
        width: 560px;
        height: 175px;
        top: 1552px;

        @media screen and (max-width: 700px) {
          width: 350px;
          height: 105px;
        }

        @media screen and (max-width: 480px) {
          width: 200px;
          height: 60px;
        }
      }
    }

    // WittyHack button
    // .wittyhack-button {
    //   width: 350px;
    //   height: 70px;
    //   border-radius: 10px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   background: linear-gradient(91.49deg,
    //       #4a2dff -2.23%,
    //       #961dcf 51.19%,
    //       #ec0ca0 104.4%);
    //   color: #fff;
    //   font-family: Poppins;
    //   font-size: 22px;
    //   font-weight: 600;
    //   line-height: 45px;
    //   letter-spacing: 0.05em;
    //   text-align: center;
    //   transition: all 0.2s ease-in-out;

    //   &:hover {
    //     transform: scale(0.95);
    //   }

    //   @media screen and (max-width: 450px) {
    //     font-size: 16px;
    //     height: 50px;
    //     width: 200px;
    //   }
    // }
  }

  //what-wittyHacks section
  .what-wittyHacks {
    .heading-1 {
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
      background: linear-gradient(
        43deg,
        rgb(65, 88, 208) 0%,
        rgb(200, 80, 192) 46%,
        rgb(255, 204, 112) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    h6 {
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
    }
    .heading-4 {
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
      span {
        color: rgb(176, 92, 255);
        font-size: larger;
      }
    }
    .description-section {
      font-size: 20px;
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
  }

  //  achievements-section
  .achievements-section {
    .heading-2 {
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
      span {
        font-size: larger;
        color: rgb(176, 92, 255);
      }
    }
    .list-style {
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
    .list-style li::marker {
      content: "★  ";
      font-size: 26px;
      color: goldenrod;
    }
    .card-section {
      .heading-5 {
        font-family: $font-text;
        letter-spacing: $text-letter-spacing;
        line-height: $text-line-height;
        background: linear-gradient(90deg, #5936b4 0%, #362a84 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    p {
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;
    }
  }

  // WhyWittyHacks
  .WhyWittyHacks {
    .heading-2 {
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;
    }
    .card-body {
      h4 {
        font-family: $font-headings;
        letter-spacing: $heading-letter-spacing;
        line-height: $heading-line-height;
      }
      p {
        font-family: $font-text;
        letter-spacing: $text-letter-spacing;
        line-height: $text-line-height;
      }
    }
  }

  //Digital badge
  .frame-section {
    background-color: #f5f5f5;
    font-family: $fontFamily;
    margin: 0 auto;

    @media screen and (max-width: 1100px) {
      flex-direction: column;
      align-items: center;
    }

    .frame-download {
      background-color: $black;
      width: 600px;
      position: relative;
      height: 600px;
      // border-radius: 10px;

      @media screen and (max-width: 700px) {
        width: 400px;
        height: 400px;
      }

      @media screen and (max-width: 500px) {
        width: 325px;
        height: 325px;
      }

      img {
        // border-radius: 10px;
        width: 100%;
        position: absolute;
        height: 100%;
      }

      .frame-img {
        // border-radius: 10px;
        z-index: 10;
        left: 0;
      }

      .user-frame-img {
        // border-radius: 10px;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    label {
      border: 2px solid #7848f4;
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      width: 250px;
      border-radius: 10px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #7968ce;
      cursor: pointer;
      font-family: $font-text;
      letter-spacing: $text-letter-spacing;
      line-height: $text-line-height;

      &:hover {
        background-color: #fff;
        border-color: #30006d;
        color: #30006d;
      }
    }

    button {
      border: 2px solid #7968ce;
      background-color: #7968ce;
      color: white;
      font-weight: 600;
      width: 250px;
      border-radius: 10px;
      height: 50px;
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;

      &:hover {
        background-color: #fff;
        border-color: #30006d;
        color: #30006d;
      }
    }

    input {
      display: none;
    }

    .form-elements {
      @media screen and (max-width: 1100px) {
        justify-content: center;
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .digital-badge {
      width: calc(100% - 600px);

      h1 {
        font-size: 62px;
        letter-spacing: 2px;
        font-family: $font-headings;
        letter-spacing: $heading-letter-spacing;
        line-height: $heading-line-height;

        @media screen and (max-width: 500px) {
          font-size: 40px;
        }
      }

      p {
        letter-spacing: 1px;
        font-family: $font-text;
        letter-spacing: $text-letter-spacing;
        line-height: $text-line-height;
      }

      @media screen and (max-width: 1100px) {
        margin-top: 50px;
        flex-direction: column-reverse !important;
        width: 100%;
      }

      @media screen and (max-width: 700px) {
        margin-top: 50px;
        // flex-direction: column-reverse !important;
        width: 70%;
      }

      @media screen and (max-width: 500px) {
        margin-top: 50px;
        padding: 0px !important;
        // flex-direction: column-reverse !important;
        width: 100%;
      }

      .hashtag {
        color: #5424e7;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .caption-heading {
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }
  .caption {
    font-family: $font-text;
    letter-spacing: $text-letter-spacing;
    line-height: $text-line-height;
    border-radius: 6px;
    letter-spacing: 0px;
    color: black;
    font-weight: 400;
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    text-align: justify;

    .copy-clipboard {
      cursor: pointer;

      &:hover {
        font-size: large;
        color: #5424e7;
      }
    }
  }

  .wallpaper-layout {
    background-color: #fff;

    // background-color: #e6e3f6;
    h1 {
      font-family: $font-headings;
      letter-spacing: $heading-letter-spacing;
      line-height: $heading-line-height;

      font-weight: 600;

      @media screen and (max-width: 500px) {
        font-size: 32px;
        justify-content: center;
      }

      @media screen and (max-width: 700px) {
        font-size: 32px;
      }
    }

    .desktop-wall-layout {
      @media screen and (max-width: 1000px) {
        justify-content: center;
      }

      @media screen and (max-width: 500px) {
        justify-content: center;
      }
    }
  }

  .desktop-wallpaper {
    border-radius: 20px;
    font-family: $fontFamily;
    font-weight: 600;

    a {
      &:hover {
        opacity: 80%;
        cursor: pointer;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
      }
    }

    .desktop-image {
      width: 400px;
      height: 250px;
      border-radius: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @media screen and (max-width: 1000px) {
        width: 600px;
        height: 350px;
      }

      @media screen and (max-width: 700px) {
        width: 450px;
        height: 250px;
      }

      @media screen and (max-width: 500px) {
        width: 350px;
        height: 200px;
        justify-content: center;
      }

      .download-wall {
        border-radius: 15px;
        font-size: 30px;
        width: 30px;
        background-color: $black;
        color: #d4d2e4;

        &:hover {
          background-color: #5533ff;
        }
      }
    }
  }

  // .zoom-image {
  //   width: 400px;
  //   height: 250px;
  //   border-radius: 30px;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   @media screen and (max-width: 1000px) {
  //     width: 600px;
  //     height: 350px;
  //   }
  //   @media screen and (max-width: 700px) {
  //     width: 450px;
  //     height: 250px;
  //   }
  //   @media screen and (max-width: 500px) {
  //     width: 350px;
  //     height: 200px;
  //     justify-content: center;
  //   }
  //   .download-wall {
  //     border-radius: 15px;
  //     font-size: 30px;
  //     width: 30px;
  //     background-color: #000000;
  //     color: #d4d2e4;
  //     &:hover {
  //       background-color: #5533ff;
  //     }
  //   }
  // }
  .mobile-image {
    width: 260px;
    height: 450px;
    // border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 1000px) {
      width: 250x;
      height: 480px;
    }

    @media screen and (max-width: 700px) {
      width: 250px;
      height: 440px;
    }

    @media screen and (max-width: 500px) {
      width: 250px;
      height: 440px;
      justify-content: center;
    }

    .download-wall {
      border-radius: 15px;
      font-size: 30px;
      width: 30px;
      background-color: $black;
      color: #d4d2e4;

      &:hover {
        background-color: #5533ff;
      }
    }
  }
}
