$heading-text: #353833;
$base-font-color: #726f77;
$light-voilet: #7968ce;
$base-font-color: #726f77;
$heading-text: #353833;
$light-voilet: #7968ce;

$primary-color: #7848f4;
$btn-font-color: #ffffff;
$btn-border-radius: 5px;
$fs-btn: 18px;

// Variables --------------------------

$text-color: #6c6c72;
$heading-color: #232233;
$fs-body: 16px;
$fs-heading: 60px;
$padding-x: 5%;
$padding-y: 7%;
$font-size-of-normal-h1: 45px;

// sagethod.scss file variable;

$hero-heading: 80px;
$text-size: 18px;
$bg-opacity: 1;
$text-opacity: 1;
$background: #121212;
$text-color: #f5f5f5;
$sagethon-family: "Inter", sans-serif;
$card-background: #171717;
$hover-text-color: #f60861;

// profile.scss file variables
$heading-text: #353833;
$base-font-color: #726f77;
$light-voilet: #7968ce;

// login.scss file variables

$heading-text: #353833;
$base-font-color: #726f77;

// landing_timeline.scss file variables

$heading-text: #353833;
$light-voilet: #7968ce;
$dark-green: #04a93b;

// header.scss file variables

$heading-text: #353833;
$base-font-color: #726f77;
$light-voilet: #7968ce;

// freeclass.scss file variables

$light-voilet: #7968ce;
$heading-text: #353833;
$head-font-size: 54px;
$light_bg: #f9fbfc;
$base-font-color: #726f77;
$font-family: "Montserrat";

// event.scss file variables

$event_light_bg: #f9fbfc;
$light_heading_font: "Quicksand", sans-serif;

// club.scss file variables

$light-voilet: #7968ce;
$dark-voilet: #30006d;
$club-bg: #e7ecfc;
$club-background: #03b194;

//blog.scss file Variables

$font-Montserrat: "Montserrat";
$font-headings: "InterDisplay", "Inter", -apple-system, BlinkMacSystemFont,
  "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$font-text: "Inter", "-apple-system", "BlinkMacSystemFont", "Helvetica Neue",
  Helvetica, "Roboto", Arial, sans-serif;
$Color_333C45: #334545;
$black: #000000;
$white: #ffffff;
$heading-line-height: 1.3;
$heading-letter-spacing: -0.015em;
$text-line-height: 1.6;
$text-letter-spacing: 0.6px;

//aisummit.scss file  Variables
$HeadingFontSize: 45px;
$PeraGraphFontSize: 20px;
$fontColorHeading: white;
$fontFamilyHeading: "Roboto", sans-serif;
$fontFamilyPera: "Roboto", sans-serif;

// academy.scss file variables
$section-bg: #f9f9fd;
$light-voilet: #7968ce;
$paratext: #676767;
$content-color: #8082a5;
$btn-bg: #7848f4;

// textcolor bootcamp file

$pera-text-color: #808080;
$pera-font-size: 16px;
