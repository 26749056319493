/* style.css */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
.header-class {
  /* background-color: #F9F9F9; */
  color: #070707;
  text-align: center;
  font-size: 18px;
}

.row-class {
  text-align: center;
}

.table td {
  text-align: center;
  vertical-align: middle !important;
}
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}
.symbol.symbol-circle,
.symbol.symbol-circle .symbol-label,
.symbol.symbol-circle > img {
  border-radius: 50%;
}
.me-3 {
  margin-right: 0.75rem !important;
}
.bl {
  height: 100px;
  width: 100px;
}

.Approve {
  background-color: #dfffea;
  color: #17c653;
}
.Disapprove {
  background-color: #ffeef3;
  color: #f8285a;
}

.desc {
  padding-bottom: 12px;
  flex: 1 0 auto;
  word-break: break-word;
  display: block;
}
.titles {
  width: -webkit-fill-available;
  max-width: 250px;
  flex: 1 0 auto;
  word-break: break-word;
  max-height: 63px;
  letter-spacing: 0;
  line-height: 21px;
  font-size: 18px;
  font-weight: 500 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  color: black;
}
/* .sub-head{
  flex: 1 0 auto;
  word-break: break-word;
  display: block;
      max-height: 40px;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 16px;
      color: #6b6b6b;
      line-height: 20px !important;
      font-weight: 400 !important;
      margin: 0;
  }
 */
