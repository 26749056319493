.template-header {
  height: 70px;
  width: 100%;
  background-color: #673de6;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.header-img {
  height: auto;
  width: auto;
}

.header-text {
  color: #ffffff;
}
