@import "./_variable";
html {
  box-sizing: border-box;
}

.vis {
  display: block;
}

.blogs {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  .blog-header-section {
    // background-color: #e2d8ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    padding: 2rem;

    // Circle pattern
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background-image: radial-gradient(
    //       circle at 5% 15%,
    //       #7848f4 15px,
    //       transparent 15px
    //     ),
    //     radial-gradient(circle at 95% 15%, #ff5f15 20px, transparent 20px),
    //     radial-gradient(circle at 50% 50%, #673de6 25px, transparent 25px),
    //     radial-gradient(circle at 15% 85%, #a768ce 18px, transparent 18px),
    //     radial-gradient(circle at 85% 85%, #7848f4 22px, transparent 22px);
    //   background-repeat: no-repeat;
    //   opacity: 0.3;
    //   z-index: 1;
    // }

    // Content styles with z-index to appear above pattern
    h1 {
      font-size: 45px;
      font-family: $font-headings;
      font-weight: 600;
      color: $black;
      cursor: pointer;
      position: relative;
      z-index: 1;
    }

    span {
      position: relative;
      z-index: 1;

      .creator-name {
        font-size: 12px;
        font-family: $font-text;
        font-weight: 600;
        color: #30006d;
      }

      .created-at {
        font-size: 12px;
        font-family: $font-text;
        font-weight: 500;
        color: #30006d;
      }
    }

    .side-img {
      position: relative;
      z-index: 1;
      border: 2px solid #7848f4;
      border-radius: 15px;
      padding: 8px;
      box-shadow: 4px 4px 0 #e2d8ff;
      transition: all 0.3s ease;

      &::before {
        content: "";
        position: absolute;
        top: -8px;
        left: -8px;
        right: -8px;
        bottom: -8px;
        border-radius: 20px;
        opacity: 0.5;
        z-index: -1;
      }
    }
  }

  .blog-container {
    min-width: 800px;
    margin: 10px 24px;
    // min-width: 0;
    width: 100%;

    .header-blog {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 0;
      color: #6b6b6b;

      // line-height: 24px;
      // border: 1px solid black;
      .blog-title {
        font-size: 24px;
      }
    }

    .blog-body {
      display: flex;
      align-items: stretch !important;
      flex-wrap: wrap;
      .blog-card {
        padding-right: 5px;
        padding-left: 5px;
        padding-bottom: 25px;
        flex-grow: 0;
        box-sizing: inherit;
        border-radius: 10px;

        .blog-card-body {
          height: 100%;
          display: block;
          min-height: 390px;
          box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);

          &:hover {
            box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.15);
          }

          .dv {
            height: 100%;

            .blog-content {
              box-sizing: content-box;
              height: 100%;
              display: block;
              width: 100%;

              .blog-a {
                display: grid;
                position: relative;
                grid-template-areas:
                  "image image image image image image image image image image image image"
                  "content content content content content content content content content content content content";
                gap: 24px 0px;
                grid-template-rows: auto 1fr;
                grid-template-columns: repeat(12, 1fr);

                .b {
                  grid-area: image;

                  .imge {
                    object-position: 50% 50%;
                    object-fit: cover;
                    aspect-ratio: 2/1;
                    border-radius: 2px;
                    background-color: #f9f9f9;
                    width: 100% !important;
                  }
                }

                .c {
                  display: flex;
                  grid-area: content;
                  flex-direction: column;
                  justify-content: center;

                  .d {
                    display: flex;
                    flex-grow: 1;
                    flex-direction: column;
                    width: 100%;

                    /* box-sizing: inherit; */
                    .author {
                      display: flex;
                      align-items: center;
                      /*  box-sizing: inherit; */
                      margin-bottom: 16px;

                      .i {
                        display: block;
                        margin-right: 8px;

                        .j {
                          width: 20px;
                          height: 20px;
                          box-sizing: border-box;
                          border-radius: 50%;
                        }

                        .blog-name {
                          margin: 0;
                          padding: 0;
                          word-break: break-all;
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 1;
                          text-overflow: ellipsis;
                          max-height: 20px;
                          overflow: hidden;
                          font-size: 13px;
                          color: #242424;
                          line-height: 20px;
                          font-weight: 400;
                          margin: 0;
                        }
                      }
                    }

                    .blog-desc {
                      padding-bottom: 12px;
                      flex: 1 0 auto;
                      word-break: break-word;
                      display: block;

                      .blog-title {
                        max-height: 48px;
                        letter-spacing: 0;
                        line-height: 24px;
                        font-size: 20px;
                        font-weight: 700 !important;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0;
                      }

                      .sub-head {
                        max-height: 40px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        font-size: 16px;
                        color: #6b6b6b;
                        line-height: 20px !important;
                        font-weight: 400 !important;
                        margin: 0;
                      }
                    }

                    .blog-card-footer {
                      font-size: 13px;
                      line-height: 20px;
                      color: #6b6b6b;
                      font-weight: 400;
                      display: flex;

                      .l {
                        display: flex;
                        height: 48px !important;
                        flex: 1 0 auto;

                        .heart {
                          margin-right: 15px;
                        }
                      }

                      .m {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        flex: 0 0 0px;
                        position: relative;

                        .drop {
                          position: absolute;
                          top: -10px;
                          right: -25px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .blog-preview {
    // max-width: 1000px;
    // margin: 0 auto;

    .author-name {
      font-weight: 500;
      font-size: 14px !important;
      font-family: $font-text;
      letter-spacing: 0.5;
      color: $black !important;
    }

    .textEditor {
      font-family: $font-text;

      h1 {
        letter-spacing: -0.015em;
        color: $black !important;
        padding-top: 54px;
        padding-bottom: 18px;
        font-family: inherit;
        font-family: $font-headings;
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 1.3;
      }

      h2 {
        font-weight: 500;
        font-family: inherit;
        font-size: 22px !important;
      }

      h3 {
        font-weight: 500;
        font-family: inherit;
        font-size: 20px !important;
      }

      h4 {
        font-weight: 500;
        font-family: inherit;
        font-size: 16px !important;
      }

      h5 {
        font-weight: 500;
        font-family: inherit;
        font-size: 14px !important;
      }

      h6 {
        font-weight: 500;
        font-family: inherit;
        font-size: 12px !important;
      }

      // Characteristics of a Community!!
      p {
        font-family: $font-text;
        font-style: normal;
        font-weight: normal;
        font-size: 16px !important;
        line-height: 1.6;
        letter-spacing: 0.6px;
        color: $Color_333C45 !important;
        -webkit-font-smoothing: antialiased;
      }

      pre {
        background-color: #2c2d3017;
        padding: 5px;
        white-space: pre-wrap;
      }

      blockquote {
        background-color: #dddddd46;
        border-left: 4px solid #83838300;
        border-left-color: rgb(46, 47, 48) !important;
        padding: 5px;
      }
    }

    .textEditorElements {
      .tip {
        border-radius: 10px !important;
        border-width: 2px 2px 2px 5px;
        border-style: solid;
        border-color: rgb(249, 235, 255) rgb(249, 235, 255) rgb(249, 235, 255)
          rgb(180, 90, 242);
        border-image: initial;

        .head {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          background-color: rgb(249, 235, 255);
          color: rgb(180, 90, 242);
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 500;
        }
      }

      .info {
        border-width: 2px 2px 2px 5px;
        border-radius: 10px !important;
        border-style: solid;
        border-color: rgb(225, 247, 255) rgb(225, 247, 255) rgb(225, 247, 255)
          rgb(23, 162, 184);
        border-image: initial;

        .head {
          background-color: rgb(225, 247, 255);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          text-transform: uppercase;
          color: rgb(23, 162, 184);
          font-size: 18px;
          font-weight: 500;
        }
      }

      .warning {
        border-width: 2px 2px 2px 5px;
        border-radius: 10px !important;
        border-style: solid;
        border-color: rgb(254, 247, 219) rgb(254, 247, 219) rgb(254, 247, 219)
          rgb(255, 192, 16);
        border-image: initial;

        .head {
          text-transform: uppercase;
          background-color: rgb(254, 247, 219);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          color: rgb(148, 79, 1);
          font-size: 18px;
          font-weight: 500;
        }
      }

      .note {
        border-width: 2px 2px 2px 5px;
        border-radius: 10px !important;
        border-style: solid;
        border-color: rgb(225, 247, 255) rgb(225, 247, 255) rgb(225, 247, 255)
          rgb(0, 123, 255);
        border-image: initial;

        .head {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          background-color: rgb(225, 247, 255);
          color: rgb(0, 123, 255);
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 500;
        }
      }

      .fact {
        border-width: 2px 2px 2px 5px;
        border-radius: 10px !important;
        border-style: solid;
        border-color: rgb(230, 230, 230) rgb(230, 230, 230) rgb(230, 230, 230)
          rgb(108, 117, 125);
        border-image: initial;

        .head {
          background-color: rgb(230 230 230);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          color: rgb(108, 117, 125);
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 500;
        }
      }

      .important {
        border-width: 2px 2px 2px 5px;
        border-radius: 10px !important;
        border-style: solid;
        border-color: rgb(254, 247, 219) rgb(254, 247, 219) rgb(254, 247, 219)
          rgb(255, 192, 16);
        border-image: initial;

        .head {
          text-transform: uppercase;
          background-color: rgb(254, 247, 219);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          color: rgb(148, 79, 1);
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }

  .blog-preview img {
    object-fit: cover;
    border-radius: 12px;
  }

  .blog-preview {
    .blog-preview-title {
      .blog-heading-text {
        font-family: $font-headings;
        font-style: normal;
        font-weight: 700;
        font-size: 54px;
        line-height: 1.1;
        color: black !important;
        letter-spacing: -0.025em;
        text-align: center;
      }

      p {
        display: block;
        color: $Color_333C45;
        font-family: $font-text;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.6;
        -webkit-font-smoothing: antialiased;
      }

      .Date-section {
        p {
          font-size: 13px;
          color: #5c6874;
          letter-spacing: 0.5 !important;
        }
      }

      .Author-Name {
        min-height: 50px;

        &:hover {
          background-color: rgba(2, 25, 30, 0.06);
          padding: 5px;
        }
      }

      .blog-title-footer {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        p {
          margin: 15px 0;
          font-size: 14px;
          color: #1a1a1a !important;
          font-weight: 600;
        }

        .share {
          span {
            font-size: 25px;

            &:hover {
              cursor: pointer;
              color: #416ee7 !important;
            }
          }
        }
      }
    }
  }

  .blog-preview .lead {
    font-size: 1rem;
    line-height: 1.8;
    color: #4a4a4a;
  }

  .blog-content {
    font-size: 1.1rem;
    line-height: 1.8;
    color: $Color_333C45;

    p {
      font-family: $font-text;
      font-style: normal;
      font-weight: normal;
      font-size: 16px !important;
      line-height: 1.6;
      letter-spacing: 1px;
      color: $Color_333C45 !important;
      -webkit-font-smoothing: antialiased;
    }

    .copytoClip {
      color: #605b5b;

      &:hover {
        color: #416ee7;
        cursor: pointer;
      }
    }
  }

  .blog-actions {
    display: flex;
    // gap: 0.1rem;
    justify-content: space-around;
  }

  .blog-actions .btn-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    // font-size: 1rem;
  }

  /* View Toggle Buttons */
  .view-toggle {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 0.25rem;
  }

  .view-toggle .btn {
    border-radius: 6px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }

  .blog-card {
    transition: transform 0.2s ease-in-out;
    cursor: pointer;

    span {
      font-size: 12px;
    }
  }

  .blog-hover:hover {
    border-radius: 10px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }

  .blog-card .card-img-top {
    height: 250px;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .blog-card .card-title {
    font-weight: 600;
    line-height: 1.4;
    font-size: 18px !important;
  }

  .blog-card .card-text {
    margin-top: 5px;
    font-size: 12px;
    line-height: 1.6;
    text-align: justify;
  }

  .badge {
    font-size: 0.8rem;
    padding: 0.5em 1em;
    font-weight: 500;
  }

  .navbar {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .navbar-brand {
    font-size: 1.5rem;
  }

  .input-group {
    max-width: 250px;
  }

  .card--footer,
  .card-left-area {
    padding: 0 0 10px 10px;

    .btn {
      border: none !important;
    }
  }

  .blog-list {
    .list-img-badge {
      top: 10px;
      color: white;
      font-weight: 600;
      text-transform: uppercase;
      background-color: #1a1a1acc !important;
      opacity: 0.7;
      border-top-right-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
    }

    transition: transform 0.2s ease-in-out;

    .blog-table-heading {
      span {
        font-size: 14px;
        font-family: $font-Montserrat;
        font-weight: 400;
      }
    }

    .blog-list-card-footer {
      span {
        font-size: 14px;
        font-family: $font-Montserrat;
        font-weight: 500;
      }
    }
  }

  .blog-list .card:hover {
    transform: translateY(-5px);
  }

  .blog-list .card-title {
    font-weight: 600;
  }

  .blog-list .object-fit-cover {
    object-fit: cover;
  }

  .blog-actions {
    button {
      border: none;
      font-size: 12px !important;
    }
  }

  .blog-headings {
    font-size: 25px;
    color: rgba(43, 43, 43, 0.842);
    border: none;
    font-weight: 700;
    font-family: $font-Montserrat;
  }

  .side-section {
    .side-section-div {
      border: 1px solid rgb(218, 218, 218);
      border-radius: 10px;
    }

    h4 {
      font-size: 18px;
      font-family: $font-text !important;
      color: $black;
      font-weight: 500;
    }

    p {
      font-family: $font-text !important;
      font-size: 12px;
      font-weight: 400;
      color: $Color_333C45;
      letter-spacing: 0;
      line-height: 1.6;
      -webkit-font-smoothing: antialiased;
    }

    a {
      font-family: $font-text;
      letter-spacing: 0;
      // line-height: 1.375rem;
      text-decoration: underline !important;
      color: $Color_333C45;
      font-weight: 500;
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    .blog-container {
      min-width: 0 !important;
    }

    .blog-preview {
      margin: 0 !important;
    }

    .blog-preview {
      .display-4 {
        line-height: 2.5rem !important;
        font-size: 2rem !important;
        text-align: unset !important;
      }
    }

    .blog-card {
      .card-img-top {
        height: 180px !important;
      }
    }

    .textEditor {
      h1 {
        margin: 25px 0 10px 0;
        font-size: 20px !important;
        line-height: 2rem;
        text-align: start;
      }
    }

    .blog-title-footer {
      align-items: start !important;
      flex-direction: column !important;

      span {
        margin: 5px 0 0 0 !important;
      }

      .share {
        margin: 0 0 10px 0 !important;

        span {
          font-size: 40px !important;
        }
      }
    }

    .blog-header-section {
      flex-direction: column-reverse;
      border-radius: 0px;
      h1 {
        font-size: 26px !important;
      }
    }
  }

  @media (max-width: 1000px) {
    .blog-header-section {
      flex-direction: column-reverse;

      h1 {
        font-size: 30px;
      }

      .side-img {
        img {
          width: 100% !important;
          height: 200px;
        }
      }
    }
  }

  font-size: 25px !important;
}

.featured-blog-preview {
  .blog-top {
    // border-top-left-radius: 15px;
    border-radius: 15px;
    height: 390px;
    // border-top-right-radius: 15px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: 0 4px 16px 4px rgba(0, 0, 0, 0.15);
    }

    .blog-img {
      width: 100%;
      height: 150px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
  }

  .blog-head {
    img {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 1px solid rgb(54, 54, 54);
      object-fit: cover;
    }

    span {
      font-size: 12px;
      font-family: $font-Montserrat;
      font-weight: 600;
      color: rgb(97 84 84 / 63%);
    }
  }

  .blog-content {
    h4 {
      font-size: 18px;
      font-family: $font-Montserrat;
      font-weight: 700;
      color: #2a2a2a;
    }

    p {
      font-size: 12px !important;
      font-family: $font-Montserrat !important;
      font-weight: 400;
      color: #4a4a4a;
    }
  }

  .blog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 12px;
      color: #4a4a4a;
      font-weight: 400;
      font-family: $font-Montserrat;
    }
  }
}

.filterr {
  button {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
  }
}

/* Back Button */
.back-btn-container {
  width: fit-content;
}

.back-button {
  font-size: 13px;
  font-family: $font-text;
  display: inline-flex;
  align-items: center;
  width: fit-content;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.2s ease;
  font-weight: 500;
  // position: relative;
  color: rgba(0, 0, 0, 0.527);
  transition: color 0.5s ease-in-out;
  border: none;
  font-weight: 800;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  height: 50px;
  width: 100%;
}

.back-button:hover {
  background-color: rgba(13, 110, 253, 0.1);
  color: black;
  font-weight: 500;
  transform: translateX(-5px);
}

.blog-content-btn {
  margin: 0 20px;

  button {
    padding: 5px 10px !important;
    font-size: 14px !important;
  }
}

.ant-radio-wrapper {
  margin-right: 0 !important;
}

.ant-radio {
  .ant-radio-inner {
    border-color: #7848f4 !important;
  }
}

.ant-radio-checked {
  .ant-radio-inner {
    background-color: #7848f4 !important;
  }
}

.quiz-question-text {
  font-family: $font-text !important;
  font-size: 18px !important;
}
