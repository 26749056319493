.language {
  display: inline !important;
  width: 150px;
}
.heading {
  font-size: 20px;
}
.source {
  width: 100% !important;
  background: #cccccc52;
  font-size: 15px;
  resize: vertical;
  textarea {
    min-height: 350px;
  }
}

.input {
  background: #cccccc52;
  width: 100%;
  min-height: 50px;
  resize: vertical;
  font-size: 17px;
}
.output {
  background: #cccccc52;
  width: 100%;
  height: 100%;
  position: relative;
  top: 7.6%;
  font-size: 18px;
  right: 0;
  resize: none;
  textarea {
    min-height: 150px;
  }
}

.input-check {
  .label {
    cursor: pointer;
  }
  .input {
    cursor: pointer;
  }
}

.reset-compiler {
  cursor: pointer;
}

.code-editor-textarea {
  textarea {
    max-height: none !important;
  }
}

.code-editor {
  .line {
    display: table-row;
  }
  .editorLineNumber {
    // position: absolute;
    // left: 0 !important;
    // color: #cccccc;
    // text-align: right !important;
    // font-weight: 100 !important;
    display: table-cell;
    text-align: left;
    padding-right: 1em;
    user-select: none;
    opacity: 0.5;
  }
  .editorText {
    display: table-cell;
  }
}

// .compiler {
//   textarea {
//     min-height: 100px;
//   }
// }
