.contributor-header-row {
  height: auto;
  align-items: center;
  padding-bottom: 5px;
  h1 {
    color: #ffffff;
    font-size: 60px;
    text-align: left;
  }
  p {
    font-size: 16px;
    color: #ffffff;
    text-align: left;
  }
  background: rgb(48, 0, 182);
  background: linear-gradient(
    81deg,
    rgba(48, 0, 182, 1) 11%,
    rgba(48, 0, 182, 1) 35%,
    rgba(79, 34, 209, 1) 57%,
    rgba(92, 48, 220, 1) 66%,
    rgba(103, 61, 230, 1) 74%,
    rgba(103, 61, 230, 1) 88%
  );
}

.intro-section {
  h1 {
    font-size: 24px;
  }
  p {
    font-size: 15px;
  }
}

.contirbute-flow-card {
  border: solid 1px #30006d;
  box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
  height: 200px;
  border-radius: 5px;
  align-items: center;
  h5 {
    font-size: 15px;
    font-weight: 700;
    margin-top: 15px;
    padding: 0px 15px 0 15px;
  }
  i {
    font-size: 55px;
  }
}

.contribute-categ-card {
  border-radius: 5px;
  border: solid 2px #30006b;
}

@media (min-width: 767px) {
}

@media (max-width: 767px) {
  .contributor-header-row {
    h1 {
      font-size: 30px;
    }
    P {
      display: none;
    }
  }
  .contributor-header-img {
    height: 180px;
    width: 300px;
    padding: 15px;
  }
  .contribute-categ-card {
    img {
      height: 100px;
      width: 100px;
    }
  }
}
