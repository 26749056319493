@import "./_variable";
.footer-section {
  background-color: #30006d;
  color: #b89ddb;
  h6 {
    color: white;
    font-family: $font-headings;
    letter-spacing: $heading-letter-spacing;
    line-height: $heading-line-height;
  }
  span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    font-family: $font-text;
    line-height: $text-line-height;
    letter-spacing: $text-letter-spacing;
  }
  .logo-section {
    border-left: solid 2px #b89ddb;
  }
  a {
    text-decoration: none;
  }
  p {
    color: #ffffff;
    font-size: 15px;
    font-family: $font-text;
    line-height: $text-line-height;
    letter-spacing: $text-letter-spacing;
  }
  .sub-footer-heading {
    font-size: 18px;
    color: #afaeaf;
    margin-bottom: 5px;
  }
}

// Footer Nav BAr ---------------------------
.footer-navbar {
  position: fixed;
  bottom: -1px;
  display: block;
  align-items: center;
  justify-content: center;
  align-content: center;

  transition: bottom 0.4s;

  z-index: 999;
  background-color: #30006d;
  width: 100%;
  height: 45px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  border: none;
  box-shadow: 0 -1px 8px 0px #afaeaf;
  height: auto;
  a {
    text-decoration: none;
  }
  i {
    color: #ffffff;
    font-size: 20px;
  }
  p {
    padding: 0;
    margin: 0;
    color: #ffffff;
    font-size: 11px;
  }
}

.footer-navbar-hide {
  bottom: -70px;
}

@media only screen and (min-width: 768px) {
  .footer-navbar {
    display: none;
  }
}
