@import "./_variable";

.event-default-bg {
  background-color: whitesmoke !important;
}
.rounded-corner {
  border-radius: 10px;
}

// Event Page
.event-page-header {
  background-color: whitesmoke !important;
  color: #000;
  .event-thumbnail {
    // width: 100%;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  .event-header-img {
    margin-bottom: 100px;
    > div {
      position: relative;
      top: 100px;
    }
  }
  .event-heading {
    color: $black;
    font-size: 35px;
    // font-weight: 900 !important;
    // font-family: $light_heading_font;
    span {
      font-weight: 600 !important;
    }
  }
  .event-host-heading {
    font-size: 14px;
    font-weight: 600;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }

  .event-sub-heading {
    color: #000;
    font-size: 20px;
    font-weight: 200;
  }
  .event-date {
    span {
      color: #777;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .event-date-time {
    span {
      font-size: 16px;
      color: #777;
      font-weight: 500;
    }
  }

  .event-location {
    display: flex;
    span {
      color: #777;
      font-size: 16px;
      font-weight: 500 !important;
    }
  }
  .event-calender {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    min-width: 45px;
    .event-cal-month {
      background-color: #694cea;
      color: white;
      padding: 0 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .event-cal-day {
      border: 1px solid black;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: large;
      font-weight: 600;
    }
  }
  .event-peoples {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    span {
      text-align: end;
      color: #777;
      font-size: 16px;
    }
  }
  .event-header-tag {
    font-size: 14px;
    color: #000;
    padding: 2px 5px;
    border: 1px solid #726f77;
    border-radius: 50px;
    margin-right: 7px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
  }
  .event-platform {
    background-color: $event_light_bg;
    border-radius: 5px;
    color: #30006d;
    font-weight: 500;
    font-size: 15px;
  }
  .event-catagory {
    background-color: #e6d6ff;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #30006d;
  }
  .profiles-img {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      li {
        &:nth-child(2) {
          position: relative;
          left: -25px;
        }
        &:nth-child(3) {
          position: relative;
          left: -50px;
        }
      }
    }
  }
}
.upcoming-event {
  .card-content {
    border-radius: 20px;
  }
  .card-img-top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  span {
    color: #555;
    font-size: 14px;
    font-weight: 400;
  }
  h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px !important;
  }
  button {
    width: 100%;
    border-radius: 40px;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.637);

    &:hover {
      background-color: #e6d6ff;
      border-color: #694cea;
      color: #694cea;
      font-weight: 700;
    }
  }
  .event-map {
    // width: 100%;
    iframe {
      width: 100%;
      border: none;
      border-radius: 10px;
    }
  }
}

.event-registration-block {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  .join-us-button {
    margin: 10px 0 !important;
  }
}

.event-share {
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-height: 200px !important;
  label {
    font-weight: 600;
    font-size: 20px;
  }
  input {
    color: #777;
  }
}
.event-people {
  min-height: 400px;
  padding: 20px;
  h4 {
    font-weight: 600;
    font-size: 20px;
  }
  h6 {
    font-size: 18px !important;
    color: #777;
    font-weight: 600;
  }
  span {
    img {
      border-radius: 50%;
      border: 1px solid black;
      object-fit: cover;
    }
    font-size: 20px;
    font-weight: 500;
  }
}
.event-schedule {
  padding: 20px;
  h4 {
    font-weight: 600;
    font-size: 20px;
  }
  span {
    i {
      color: rgba(0, 0, 0, 0.658) !important;
    }

    color: #777;
  }
  h5 {
    font-size: 18px !important;
    color: $black;
  }
  .hr {
    // background-color: #1671a6;
    border-top: 1px solid #9874ff;
    margin-top: 45px;
  }
  h6 {
    font-size: 16px !important;
    color: white;
    background-color: #9874ff;
    border-radius: 20px;
    width: fit-content;
    padding: 10px;
    position: relative;
    top: -20px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.event-timelines {
  .event-timelines-table {
    background-color: rgba(29, 78, 216, 0.17);
    border-radius: 10px;
  }
}

.event-resource {
  .event-resource-type {
    font-weight: 500;
    font-size: 14px !important;
    color: $black;
    b {
      font-weight: 500;
      color: $black;
    }
  }
}

.event-partners {
  img {
    mix-blend-mode: multiply;
    border-radius: 5px;
  }
}
.event-gallery {
  .object-fit {
    object-fit: cover !important;
  }

  .event-gallery-img {
    height: 250px !important;
    width: 100% !important;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .event-gallery-img2 {
    height: 250px !important;
    width: 100% !important;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  .react-multi-carousel-list .react-multi-carousel-item {
    padding: 0 !important;
  }
}

.event-social-icons {
  display: flex;
  justify-content: center;
  span {
    display: block;
    width: 34px;
    height: 34px;
    border-radius: 5px;
    padding: 8px;
    background: $event_light_bg;
    border: 1px solid #000;
    color: #000;
    text-align: center;
    margin-right: 5px;
    line-height: 20px;
  }
}

.event-reg-section {
  background-color: $event_light_bg;
}
.event-speaker {
  .speaker-card {
    width: 225px;
    margin: 0px auto;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .speaker-image {
    width: 100px;
    height: 100px;
    margin: 20px auto;
    border: 2px solid #fff;
    border-radius: 50%;
  }

  .speaker-name {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .speaker-bio {
    font-size: 14px;
    color: #666;
  }

  .speaker-designation {
    font-size: 12px;
    color: #999;
    margin-top: 10px;
  }
}

.event-description {
  border-radius: 10px;
  p {
    font-size: 20px;
    // color: white;
    font-weight: 300;
    padding: 10px;
    h3 {
      // font-size: ;
    }
  }
}

.register-text {
  font-size: 30px;
  font-family: 300 !important;
}

.custom-button {
  background-color: #ffffff;
  border-radius: 30px;
  color: #30006d;
  font-weight: 600;
  font-family: "Fira Code", monospace;
  padding: 8px;
  border: solid 1px #30006d;
  margin-top: 35px;
  width: 170px;
  height: 46px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
  overflow: hidden;
  align-items: center;
  > span {
    text-align: center;
    font-weight: 600;
    font-family: "Fira Code", monospace;
    align-items: center;
    color: #30006d;
    font-weight: bold;
    font-size: 16px;
    position: relative;
    z-index: 1;
    transition: all 0.5s ease;
    white-space: nowrap;
  }
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #6e53bde7;
    position: absolute;
    transition: all 0.5s ease;
  }
  &:hover {
    span {
      color: #ffffff !important;
      i {
        color: #ffffff;
      }
    }
  }
}

.reg-button {
  background-color: #30006d;
  color: #ffffff;
  font-weight: 400;
  border-radius: 7px;
  align-items: center;
  min-height: 50px;
  min-width: 100%;
}

.custom-button {
  &:before {
    top: 0;
    left: -210px;
    color: #ffffff;
  }
  &:hover {
    &:before {
      left: 0;
      color: #ffffff;
    }
  }
}

//  Event List page

.create-event-promo {
  min-height: 100px;
  background-color: #30006d;
  h1 {
    color: #ffff;
    font-size: 23px;
  }
}

.event-carousel {
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 11%,
    rgb(255, 255, 255) 35%
  ) !important;

  h1 {
    color: rgb(23, 3, 54);
  }
  h2 {
    color: rgb(20, 130, 0);
  }
  .qoute {
    h6 {
      color: rgb(43, 54, 45);
    }
    color: #000;
  }
}

.event-status-head {
  border-bottom: 1px solid #ddd;
  .event-state {
    padding: 15px;
    h1 {
      font-size: 35px;
      color: #17171ad0;
    }
  }
}

.event-list-section {
  background-color: #f8f9fa;
}

.event-list-nav {
  justify-content: flex-end;
  display: flex;
  align-items: center;

  .filter-icon {
    font-size: 30px;
  }
  .my-events {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.event-filter {
  position: absolute;
  top: 100%;
  right: 10%;
  z-index: 9;
  transition: all 0.5s ease-in-out;
  min-width: 50%;
  min-height: 300px;
  border-radius: 5px;
}

.event-promot-section {
  background-color: #30006d;
  color: #ffffff;
  min-height: 400px;
  h3 {
    font-size: 25px;
    color: white;
  }
  p {
    color: #ffffff;
    font-size: 16px;
    padding: 20px 50px;
  }
}

// Event Card

.event-card {
  border: solid 1px rgba(204, 204, 204, 0.842);
  border-radius: 10px;
  height: 100%;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:hover {
    // box-shadow: 0 5px 35px #99a7c571;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.8);
    text-decoration-style: none !important;
    .hover-sutter {
      height: 40%;
      padding-top: 10px;
      background-color: #2f006d72;
    }
  }
  .hover-sutter {
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background-color: #30006d;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.5s ease-in-out;
    bottom: 0;
    line-height: 18px;
    color: #fff;
    .btn {
      margin-top: 40px;
    }
  }

  .event-card-title {
    font-size: 18px;
    color: #17171ad0;
    text-decoration: none;
  }
  .event-card-sub-heading {
    font-size: 16px;
    color: rgb(59, 59, 59);
  }

  .reg-status-block {
    position: absolute;
    top: 5%;
    right: 0;
    color: white;
    padding: 8px 10px;

    // border-top-left-radius: 5px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #e6f4ea;
    color: #1e8e3e;
    font-size: 10px;
    line-height: 11px;
  }
  .event-host-action-toggle {
    position: absolute;
    top: 5%;
    right: 0;
    color: white;
    padding: 8px 10px;
    // border-top-left-radius: 5px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #e6f4ea;
    color: #1e8e3e;
    font-size: 10px;
    line-height: 11px;
  }

  .date-block {
    position: absolute;
    bottom: 50%;
    right: 5%;
    padding: 5px 10px;
    border-radius: 7px;
    background-color: #1671a6;
    text-align: center;
    color: #ffffff;
    h1 {
      font-size: 20px;
      margin-bottom: 0;
      color: #ffffff;
    }
    p {
      margin-bottom: 0;
      font-size: 13px;
    }
  }
  p {
    font-size: 15px;
    color: rgb(104, 103, 103);
    margin-bottom: 7px;
  }

  .event-card-footer {
    display: grid;
    align-items: stretch !important;
    bottom: 5%;
    width: 100%;
  }
}

// Featured Card

.featured-event-card {
  border: solid 1px rgba(204, 204, 204, 0.842);
  border-radius: 10px;
  height: 100%;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    text-decoration-style: none !important;
    .featured-card-hover-sutter {
      width: 100%;
      height: 100%;
      padding-top: 35px;
      background-color: #2f006d72;
    }
  }

  .featured-card-hover-sutter {
    text-align: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 0%;
    height: 0%;
    overflow-y: hidden;
    overflow-x: hidden;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    background-color: #30006d;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.5s ease-in-out;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 200;
    line-height: 18px;
    color: #fff;
    .btn {
      margin-top: 40px;
    }
  }
  .featured-event-img {
    height: 100%;
    width: 100%;
    min-height: 200px;
    background-size: cover;
    background-position: 50%;
    background-color: #f1f3f4;
    background-position: center center;
    background-repeat: no-repeat;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-top-right-radius: 0 !important;
  }
  .featured-event-title {
    font-size: 30px;
    color: #17171ad0;
    text-decoration: none;
  }
  .event-card-sub-heading {
    font-size: 16px;
    color: rgb(59, 59, 59);
  }

  .reg-status-block {
    position: absolute;
    top: 5%;
    right: 0;
    color: white;
    padding: 8px 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: #e6f4ea;
    color: #1e8e3e;
    font-size: 10px;
    line-height: 11px;
  }
  .date-block {
    position: absolute;
    bottom: 5%;
    right: 10%;
    padding: 5px 10px;
    border-radius: 7px;
    background-color: #1671a6;
    text-align: center;
    color: #ffffff;
    h1 {
      font-size: 20px;
      margin-bottom: 0;
      color: #ffffff;
    }
    p {
      margin-bottom: 0;
      font-size: 13px;
    }
  }
  p {
    font-size: 15px;
    color: rgb(104, 103, 103);
    margin-bottom: 7px;
  }

  .event-card-footer {
    display: grid;
    align-items: stretch !important;
    bottom: 5%;
    width: 100%;
  }
}

.fuck-line {
  text-decoration: none !important;
}

.event-tag {
  font-size: 12px;
  color: #000;
  padding: 2px;
  // border: solid 1px pink;
  border-radius: 50px;
  margin-right: 7px;
  margin-top: 7px;
  text-decoration: none;
  text-align: center;
  display: inline-block;

  &:nth-child(1) {
    color: #694cea;
    border: solid 0.5px #694cea;
  }
  &:nth-child(2) {
    color: orangered;
    border: solid 0.5px orangered;
  }
  &:nth-child(3) {
    color: olivedrab;
    border: solid 0.5px olivedrab;
  }
}

// Event Dashboard :
.event-dashboard-header {
  ul {
    margin-top: 20px;
    margin-bottom: 0;
    display: flex;
    padding: 0;
    border-bottom: 1px solid #ddd;
    li {
      list-style: none;

      span {
        cursor: pointer;
        font-weight: 600;
        display: block;
        padding: 6px 25px;
        color: #000;
        font-size: 14px;
        &.active {
          border-bottom: solid 2px #30006d;
          color: #30006d;
        }
        &.active::before {
          width: 3px;
          background-color: #30006d;
        }
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}

.link-gift-icon {
  color: #726f77;
  font-size: 65px;
}

// Event Create Form
// .event-reg {
//}

.form-shadow {
  inset: 0px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  background: rgb(255, 255, 255);
}

.event-form {
  position: relative;
  width: 100%;
  height: 90vh;
  .form-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // opacity: 0.9;
    filter: brightness(0.5);
    z-index: -1;
  }
  .title {
    position: absolute;
    top: 40%;
    left: 5%;

    h1 {
      color: #ffffff !important;
      z-index: 100;
      font-size: 45px;
      line-height: 80px;
      font-weight: 600 !important;
    }
    h2 {
      color: white !important;
      text-align: center;
    }
    h3 {
      color: #fff !important;
      z-index: 100;
      text-align: center;
      font-weight: 500 !important;
    }
  }
  .form-content {
    position: absolute;
    overflow-y: scroll;
    scrollbar-width: none;
    top: 5%;
    // bottom: 10%;
    left: 40%;
    // transform: translate(-50%, -50%);
    width: 700px;
    height: 700px;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
  }
  .form-check {
    padding-left: 0 !important;
  }
}
.empty {
  height: 200px;
  width: 100%;
}

.carousel-container {
  width: 100%;
}

@media (max-width: 767px) {
  .event-page-header {
    .event-heading {
      font-size: 25px;
    }
    .event-header-img {
      margin-bottom: 50px;
      background-color: #ffffff !important;
      > div {
        top: 0;
      }
    }
    .event-sub-heading,
    .event-organizer {
      font-size: 16px;
      font-family: 300 !important;
    }
    .event-date-time,
    .event-location {
      i {
        min-width: 16px;
      }
      &::before {
        min-width: 16px;
      }
      border-left: none !important;
      // padding: 6px 16px;
      font-size: 16px;
      font-family: 300 !important;
    }
    .event-location {
      // text-align: center !important;
    }
    .event-date-icon {
      font-size: 20px;
    }
    .event-peoples {
      margin-left: 40px;
    }
  }
  .event-description {
    p {
      font-size: 16px;
    }
  }

  .event-status-head {
    .event-state {
      padding: 10px;
      h1 {
        font-size: 25px;
      }
    }
  }
  .reg-button {
    min-width: 80%;
  }
  .event-carousel {
    h1 {
      font-size: 60px;
      text-align: left;
    }
  }
  main {
    justify-content: space-between;
  }
  .left {
    order: 2;
  }

  .right {
    order: 1;
  }
  .event-filter {
    top: 130%;
    right: 10%;
    min-width: 70%;
    min-height: 300px;
  }

  .event-card {
    position: relative;
    .reg-status-block,
    .event-host-action-toggle {
      right: 0;
    }
  }
  .event-promot-section {
    background-color: #30006d;
    color: #ffffff;
    min-height: 400px;
    h2 {
      font-size: 22px;
      text-align: center;
    }
    h3 {
      text-align: center;
      font-size: 20px;
      color: white;
    }
    p {
      color: #ffffff;
      font-size: 13px;
      padding: 10px 20px;
    }
  }
  .event-form {
    position: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .form-img {
      img {
        height: 600px;
      }
    }
    .title {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100% !important;
      left: 0;
      top: 100px !important;
      h2 {
        transform: rotate(90deg);
      }
    }
    .form-content {
      position: relative;
      margin: 10px 0;
      top: 100px;
      left: 0;
      width: 90%;
    }
  }
  .empty {
    height: 80px !important;
  }
  .empty-feed {
    height: 1000px !important;
  }
}
