@import './_variable';

.login {
  height: auto;
  .login-card {
    padding: 10px;
  }

  .login-section {
    background-color: rgb(243, 243, 243);
  }

  .google-button {
    display: flex;
    flex-direction: row;
    background-color: rgb(66, 133, 244);
    width: 100%;
    box-sizing: content-box;
    cursor: pointer;
    padding: 0px 2px 0px 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(66, 133, 244);
    border-image: initial;
    border-radius: 2px;

    .google-login-string {
      color: rgb(255, 255, 255);
      font-size: 14px;
      font-weight: bold;
      font-family: "Akzidenz", "Helvetica Neue", Helvetica, Arial, sans-serif;
      line-height: 40px;
      height: 40px;
    }
  }

  .or-line {
    position: relative;
    text-align: center;
    width: 100%;
    margin: 20px 0px;
  }

  .login-text {
    font-family: "Akzidenz", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: $heading-text;
    font-size: 26px;
    line-height: 40px;
    letter-spacing: 0px;
    margin: unset;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .login-button {
    color: lightgray;
    position: relative;
    display: inline-flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    font-family: "Akzidenz", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    background-color: #30006b;
    border-radius: 5px;
    transition: all 120ms ease 0s;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    border-color: rgb(184, 196, 194);
    width: 100%;
    justify-content: center;
  }
  .signup-string {
    align-items: center;
    font-size: 12px;
    color: rgb(61, 79, 88);
    font-weight: bold;
    line-height: 16px;
    text-align: start;
    white-space: nowrap;
  }
  .input-group {
    input {
      position: relative;
      width: 100%;
      // z-index: -1;
      &:focus {
        // z-index: -1;
      }
    }
    .input-group-append {
      // border: 1px solid #30006b;
      position: absolute;
      right: 0;
      // top: 10px;
      z-index: 10;
    }
  }
}

.link-text {
  font-size: 16px;
  color: $heading-text;
}

@media (max-width: 767px) {
  .img-section {
    width: 100px;
    height: 100px;
    align-items: center;
  }
  // .login-section {
  //   margin-top: 170px;
  // }
  .login {
    .signup-string {
      white-space: pre-wrap;
    }
  }
}

@media (min-width: 1400px) {
  .login {
    height: 81vh !important;
  }
}
