.appointment-event-link-section {
  border-radius: 7px;

  .datacode-title {
    text-transform: uppercase;
    color: rgba(26, 26, 26, 0.61);
    font-size: 11px;
    font-weight: 800;
  }
  .time-date-text {
    color: rgba(26, 26, 26, 0.645);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: start;
    margin-bottom: 15px;
  }
  .slot-list-section {
    .slot-bar {
      width: 100%;
      border-radius: 5px;
      cursor: pointer;
      :hover {
        background-color: #f7f7f7;
      }
    }
    .booked {
      background-color: #ccc;
    }
    .selected {
      background-color: #22f200;
    }
  }
  .back-icon {
    cursor: pointer;
    border: 1px solid #ccc;
    color: #000;
    // height: 25px;
    // width: 25px;
    display: inline-block;
    position: relative;
    font-size: 10px;
    border-radius: 50%;
    :hover {
      background-color: #673de6 !important;
      color: #fff;
      border-radius: 50%;
    }
  }
}
