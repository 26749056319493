.category-page {
  .category-header {
    // border: solid 2px #30006d;
    // border-radius: 5px;
    background: linear-gradient(81deg, rgb(255, 255, 255) 11%);

    align-items: center;

    .left-header-section {
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 50px;
        font-weight: 700;
        align-items: center;
      }

      h2 {
        font-size: 40px;
        align-items: center;
      }
    }
  }

  .tech-tutorial-header {
    // box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
    .nav-link {
      font-size: 14px;

      a {
        color: rgba(63, 62, 62, 0.705) !important;

        &:hover {
          color: #673de6 !important;
          text-decoration: none !important;
        }
      }
    }

    align-items: center;

    .left-header-section {
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 45px;
        font-weight: 700;
        color: #4d4d4d;
        align-items: center;
      }

      h2 {
        font-size: 25px;
        align-items: center;
        color: #7968ce;
      }

      p {
        text-align: justify;
        letter-spacing: 0.4px !important;
      }

      button {
        background-color: #673de6;
        color: white;
        font-weight: 600;
        border: none;
        outline: none;

        &:hover {
          background-color: #6851db;
          cursor: pointer;
        }
      }
    }
    

    .right-header-section {
     
      button {
        background-color: #673de6;
        color: white;
        font-weight: 600;
        border: none;
        outline: none;

        &:hover {
          background-color: #6851db;
          cursor: pointer;
        }
      }

      @keyframes float {
        0% {
          transform: translateY(0px);
        }

        50% {
          transform: translateY(-10px);
        }

        100% {
          transform: translateY(0px);
        }
      }
    }
  }

  .tutorial-course-display-section {
    background-color: #f8f7fa;

    .pagination {
    }
  }

  .search {
    padding: 10px 50px 10px 50px;
    height: 50px;
    width: 100%;
    border: solid 2px #797676;
    border-radius: 5px;
    outline: none;
  }

  .search-icon {
    align-items: center;
    font-size: 20px;
    color: #30006d;
  }

  .tech-card {
    border: none;
    box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
    height: auto;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    display: flex;
    align-items: center;
  }
}

.tech-tag-col {
  ul {
    list-style-type: none;
    overflow-x: scroll;
    overflow-y: hidden;
    height: auto;
    white-space: nowrap;
    display: flex;
    padding: 0;

    li {
      display: flexbox;
    }

    :hover {
      background-color: rgba(0, 0, 0, 0.09);
      height: auto;
      border-radius: 5px;
    }
  }

  ul {
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f1efef;
    }

    &::-webkit-scrollbar {
      width: 2px;
      height: 10px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #c3c3c3;
    }
  }

  .tech-tag {
    align-items: center;
    background-color: #f1efef;
    border-radius: 5px;
    border: solid 1px #797676;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    padding: 10px;
    margin: 10px;
  }

  .category-tag {
    width: auto;
    justify-content: center;
    align-items: center;
    background-color: #c7bdd4;
    border-radius: 7px;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    padding: 10px;
    margin: 10px;

    p {
      font-size: 11px;
    }
  }
}

.tutorial-community-card {
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 35px;
    font-weight: 600;
  }

  .flow-card {
    border: solid 1px #797676;
    box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
    height: 220px;
    border-radius: 5px;
    align-items: center;

    h5 {
      font-size: 15px;
      font-weight: 500;
      margin-top: 15px;
    }

    i {
      font-size: 55px;
    }

    .red {
      color: rgb(150, 1, 38);
    }

    .green {
      color: rgb(46, 131, 12);
    }

    .blue {
      color: rgb(6, 67, 124);
    }

    .orange {
      color: orangered;
    }

    .yellow {
      color: yellow;
    }
  }
}
.tutorial-card-section {
  .pagination {
    .page-number {
      background-color: white;
    }
    .active {
      background-color: #7968ce !important;
    }
  }
}

.tutorial-card {
  // border: solid 1px #30006d;
  box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
  height: auto;
  width: 300px;
  border-radius: 5px;
  padding: 10px;

  h6 {
    font-size: 15px;
  }

  p {
    font-size: 12px;
    letter-spacing: 0.4px !important;
  }

  .tag {
    padding: 2px 5px 2px 5px;
    font-size: 10px;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(46, 131, 12);
  }

  i {
    font-size: 20px;
  }

  .red {
    color: rgb(150, 1, 38);
  }

  .green {
    color: rgb(46, 131, 12);
  }

  .blue {
    color: rgb(6, 67, 124);
  }

  .orange {
    color: orangered;
  }

  .voilet {
    color: #673de6;
  }

  .light-voilet {
    color: #7968ce;
  }

  .yellow {
    color: yellow;
  }

  .card-image {
    height: 170px;

    img {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.tutorial-list-card {
  h6 {
    font-size: 15px;
  }

  p {
    font-size: 12px;
  }

  box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
  // height: 200px;
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
  border: none;
  padding: 10px 0 10px 0;

  img {
    max-height: 180px;
    // object-fit: ;
  }
}

.tutorial-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .category-header {
    .nav-link {
      font-size: 14px;
      font-weight: 500;

      a {
        color: rgba(63, 62, 62, 0.705) !important;

        &:hover {
          color: #673de6 !important;
          text-decoration: none !important;
        }
      }
    }
  }

  .tutorial-name {
    h1 {
      font-size: 45px;
      font-weight: 700;
      color: #4d4d4d;
      align-items: center;
    }

    h2 {
      font-size: 25px;
      align-items: center;
      color: #7968ce;
    }
  }

  .right-header-section {
    img {
      animation: float 3s ease-in-out infinite;
    }

    @keyframes float {
      0% {
        transform: translateY(0px);
      }

      50% {
        transform: translateY(-10px);
      }

      100% {
        transform: translateY(0px);
      }
    }
  }

  p {
    font-size: 14px;
    letter-spacing: 0.4px;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 700;
    color: black;
  }

  .red {
    color: rgb(150, 1, 38);
  }

  .green {
    color: rgb(46, 131, 12);
  }

  .blue {
    color: rgb(6, 67, 124);
  }

  .orange {
    color: orangered;
  }

  .yellow {
    color: yellow;
  }

  .start-learning-btn {
    width: auto;
    height: 50px;
    border-radius: 8px;
    background-color: #673de6;
    color: #ffffff;
    font-size: 14px;
    align-items: center;
    justify-content: center;
  }

  .submitter-card {
    a {
      text-decoration: none;

      h6 {
        font-size: 14px;
        color: #4d4d4d;
        letter-spacing: 0.4px;
      }

      img {
        height: 45px;
        width: 45px;
        object-fit: fill;
      }

      span {
        font-size: 12px;
        color: #4d4d4d;
      }
    }
  }

  .tutorial-feedback {
    background-color: #f8f7fa;
  }
}

.filter {
  background-color: #fff;
  border-radius: 10px;
  height: 500px;
  color: #ffffff;
  align-items: center;
  box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);

  h5 {
    // color: #fff;
  }

  h6 {
    // color: #fff;
    margin-bottom: 15px;
    margin-top: 25px;
  }

  input {
    cursor: pointer;
  }

  input[type="checkbox"] {
    width: 13px;

    &:checked {
      border-color: #673de6;
    }
  }

  p {
    cursor: pointer;
    font-size: 13px;
    font-weight: 400 !important;
    margin-bottom: 0px;
    align-items: center;
    // color: #ffffff !important;
  }

  hr {
    // color: #ffffff;
  }
}

.show-filter {
  background-color: #30006d;
  height: auto;
  width: 30%;
  border-radius: 5px;

  h5 {
    font-size: 17px;
    color: #ffffff;
  }

  transition: ease-in-out 3s;
}

.add-tutorial {
  background-color: #fff;
  box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
  border-radius: 10px;
  align-items: center;

  a {
    color: white;
    font-weight: 600;
  }

  h5 {
    font-size: 16px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    letter-spacing: 0.4px !important;
  }

  button {
    border: none;
    background-color: #673de6;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: ease-in-out 0.5s;
    border-radius: 5px;

    &:hover {
      background-color: #4a3d8d;
    }
  }
}

.share-qr {
  background-color: #fff;
  box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
  border-radius: 10px;

  h4 {
    font-size: 18px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    letter-spacing: 0.4px !important;
  }

  span {
    font-size: 9px;
    font-weight: 500;
    color: #797676;
  }
}

.comment-section {
  text-align: center;

  h4 {
    color: #4d4d4d;
  }
}

.comment-name {
  text-align: left !important;
}

.user-comment {
  border: solid 1px #30006d;
  box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
  height: auto;
  border-radius: 10px;
  align-items: center;
  width: 600px;
  margin: 0 auto;

  span {
    font-size: 10px;
    color: rgb(124, 123, 123);
  }
}

.add-totorial {
  .add-form {
    border: none;
    padding: 15px;
    position: sticky;
    box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
    height: auto;
    border-radius: 5px;
  }

  h2 {
    font-size: 28px;
  }

  .add-button {
    background-color: #673de6;
    color: #ffffff;
    font-size: 13px;

    i {
      color: #ffffff;
      font-size: 13px;
    }
  }

  .edit-button {
    background-color: rgb(46, 131, 12);
    color: #ffffff;
    font-size: 13px;

    i {
      color: #ffffff;
      font-size: 13px;
    }
  }
}

.cancel-button {
  background-color: grey;
  color: #ffffff;
  font-size: 13px;

  i {
    color: #ffffff;
    font-size: 13px;
  }
}

.type-tag {
  // justify-content: center;
  align-items: center;
  display: flex;

  .type-checkbox {
    display: flex;
  }

  input {
    cursor: pointer;
    font-size: 12px;
  }

  label {
    p {
      cursor: pointer;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0px;
      align-items: center;
    }
  }
}

.tags-input {
  width: 100%;
  margin: 10px;

  .tags-area {
    width: 100%;
    border: solid 2px #673de6;
    height: auto;
    // padding: 5px;
    border-radius: 2px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    .tag {
      margin: 5px;
      padding: 0 5px 0 5px;
      width: auto;
      font-size: 11px;
      font-weight: 700;
      color: #ffffff;
      display: flex;
      border-radius: 3px;
      background-color: #673de6;
      align-items: center;
    }

    input {
      outline: none;
      border: none;
    }
  }
}

.delete-tutorial-modal {
  .modal-header,
  h5 {
    font-size: 15px;
    color: #30006d;
    font-family: "Akzidenz", "‘Helvetica Neue’", Helvetica, Arial, sans-serif;
    font-weight: 700;
  }
}

.fontSearch {
  justify-content: center;
  text-align: center;
  position: relative;
  width: 100%;
}

.fontSearch i {
  position: absolute;
  left: 35px;
  top: 16px;
  color: gray;
}

// Mobile visible=-------

@media (max-width: 767px) {
  h2 {
    font-size: 20px;
  }

  .user-comment {
    width: auto;
  }

  .add-totorial {
    .add-form {
      position: relative;
    }
  }

  .filter-section {
    display: flex;
    flex-direction: column-reverse;
  }

  .filter-text {
    display: none;
  }

  .category-page {
    .tech-tutorial-header,
    .category-header {
      .left-header-section {
        h1 {
          font-size: 32px !important;
          text-align: center;
        }

        h2 {
          font-size: 16px;
          text-align: center;
        }

        .desktop-tutorial-text {
          display: none;
        }

        // p {
        //   text-align: center;
        //   font-size: 16px;
        //   margin-top: 10px;
        //   padding: 0 10px 0 10px;
        // }
      }

      // .right-header-section {
      //   img {
      //     position: relative;
      //     right: 10px;
      //     padding: 5px;
      //     height: 160px;
      //     width: 190px;
      //   }
      //   .tech-turorial-img {
      //     text-align: right;
      //     height: 100px;
      //     width: 100px;
      //     position: relative;
      //     right: -10px;
      //   }
      // }
      .nav-link {
        font-size: 12px;
        font-weight: 500;
        color: black;
      }
    }

    .tutorial-community-card {
      border: none;

      h2 {
        font-size: 23px;
        margin-top: 15px;
      }

      .flow-card {
        height: 200px;
      }
    }
  }

  .tutorial-page {
    .left-header-section {
      order: 2;
    }

    .tutorial-name {
      h1 {
        font-size: 32px !important;
      }
    }

    .right-header-section {
      order: 1;
    }

    h1 {
      font-size: 24px;
    }

    .nav-link {
      font-size: 12px;
      font-weight: 500;
      color: black;
    }
  }

  .tech-tag-col {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    ul {
      height: auto;
      border-right: none;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      display: flex;
      padding: 0;

      li {
        display: flexbox;
      }

      :hover {
        background-color: rgba(0, 0, 0, 0.09);
        box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
        height: auto;
        border-radius: 5px;
      }
    }
  }

  .recommend-section {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    white-space: nowrap;
  }

  .submitter-card {
    img {
      height: 60px;
      width: 60px;
    }
  }

  .fontSearch {
    width: 100%;
  }

  .fontSearch i {
    position: absolute;
    left: 25px;
    top: 16px;
    color: #673de6;
  }
}

@media (min-width: 767px) {
  .mobile-tutorial-text {
    display: none;
  }
}
