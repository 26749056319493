@import './_variable';

.header {
  width: 100%;
  height: 60px;
  background-color: whitesmoke;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  position: sticky;
  top: -3px;
  transition: top 0.3s;

  .card-shadow {
    border: none;
    box-shadow: 0px 5px 6px 1px rgba(59, 73, 94, 0.2);
    height: auto;
  }
  .header-text {
    text-align: center !important;
    font-size: 20px;
    text-transform: uppercase;
  }
  .header-logo-show {
    text-align: center !important;
    font-size: 20px;
    text-transform: uppercase;
  }
  .join-us {
    width: 100px;
    border: solid 1px $light-voilet;
  }

  .left-section {
    // justify-content: space-between;
    display: flex;
    align-items: center;
    .toggle-button {
      // justify-content: space-around;
      cursor: pointer;
      color: $heading-text;
      font-size: 20px;
    }

    .category-dropdown {
      width: auto;
      color: $heading-text;
      border: solid 1px $heading-text;
      margin-left: 25px;
      border-radius: 7px;
      background-color: whitesmoke;
    }
    .dropdown-menu {
      h2 {
        font-size: 20px;
      }
      a {
        text-decoration: none;
      }
      margin-top: 20px;
      border: solid 1px #353833;
      border-radius: 5px;
      width: 800px;
      height: auto;
    }
  }
  .right-section {
    justify-content: flex-end;
    align-items: center;
    .profile-dropdown {
      width: auto;
      color: #30006d;
      text-decoration: none;
      .dropdown-header {
        color: $light-voilet;
        border-bottom: solid 1px #7968ce;
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 600;
        span {
          font-size: 16px;
          color: $heading-text;
        }
      }
      .dropdown-menu {
        background-color: #ffffff;
        align-items: center;
        a {
          text-decoration: none;
        }
        // &:hover {
        //   background-color: #7968ce;
        //   color: #ffffff;
        // }
        .dropdown-item {
          outline: none;
          color: $heading-text;

          i {
            color: $heading-text;
            font-size: 14px;
          }
          span {
            color: $heading-text;
            text-decoration: none;
            font-size: 14px;
            font-weight: 400;
          }
          &:hover {
            span,
            i {
              color: #ffffff;
            }
            background-color: #673de6;
            opacity: 1;
            color: #ffffff !important;
          }
        }
      }
    }
  }
}

.login-show-btn {
  width: 150px;
  color: #ffffff;
  background-color: $light-voilet;
  &:hover {
    color: $heading-text;
    background-color: #ffffff;
    border: 1px solid $light-voilet;
  }
}

.header-hide {
  top: -80px;
}

.header-upper {
  a {
    text-decoration: none;
  }
  :hover {
    color: #30006b;
  }
  padding: 5px 0 5px 0;
  span {
    color: #673de6;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
  }
}

// Our Story SCSS

.our-story-header,
.our-partners-header {
  height: 380px;
  background: rgb(48, 0, 182);
  background: linear-gradient(
    180deg,
    rgba(48, 0, 182, 1) 11%,
    rgba(48, 0, 182, 1) 35%,
    rgba(79, 34, 209, 1) 57%,
    rgba(92, 48, 220, 1) 66%,
    rgba(103, 61, 230, 1) 74%,
    rgba(103, 61, 230, 1) 88%
  );
  h1 {
    font-size: 60px;
    color: #ffffff;
  }
}

.tag-line {
  font-size: 50px;
  font-style: italic;
}

.our-community {
  justify-content: center;
  align-items: center;
  .social-card {
    align-items: center;
    text-align: center;
    margin: 10px;
    height: auto;
    border-radius: 5px;
    padding: 10px 0 20px 0;
    h1 {
      font-size: 25px;
    }
    h2 {
      font-size: 18px;
      white-space: nowrap;
    }
    p {
      font-size: 12px;
      padding: 0 10px 0 10px !important;
    }
    .social-follow-btn {
      border: solid 2px #30006d;
      font-size: 13px;
    }
  }
}

.react-multi-carousel-list {
  button {
    outline: none;
  }
  .react-multi-carousel-item {
    width: 300px;
    padding: 0 20px 0 20px;
    button {
      outline: none;
    }
  }
}

.react-multiple-carousel__arrow {
  display: none;
}
.react-multiple-carousel__arrow:hover {
  background-color: #673de6;
  min-height: 33px;
  min-width: 33px;
}

.people-card {
  padding: 20px;
  height: 100%;
  width: 100%;
  border-radius: 7px;
  .rounded-circle {
    height: 60px;
    width: 60px;
  }
  .footer {
    display: grid;
    bottom: 0;
  }
  .quote {
    i {
      background-color: #673de6;
      border-radius: 50%;
      text-align: center;
      padding: 15px;
      font-size: 30px;
      color: #ffffff !important;
      text-align: left !important;
      position: relative;
      top: -40px;
    }
  }
  .people-card-body {
    padding: 20px;
    p {
      font-size: 14px;
      font-weight: 500;
    }
    i {
      color: $light-voilet;
    }
    h2 {
      padding-top: 10px;
      font-size: 18px;
      color: $heading-text;
    }
  }
}

.add-testimomnial-btn {
  justify-content: center;
  background-color: #30006d;
  color: #ffffff;
}

.testimonial-form {
  padding: 20px;
  justify-content: center;
  text-align: center;
  h2 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  // .toggle-button {
  //   display: none;
  // }
  .header-text-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .header {
    height: auto;
    .left-section {
      // display: flex;
      // justify-content: space-between;
      align-items: center !important;
      .header-text-mobile {
        font-size: 27px;
        text-transform: uppercase;
      }
      .category-dropdown {
        display: none;
      }
    }
    .header-profile {
      justify-content: flex-end;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .join-us {
      display: none;
    }
    .login-show-btn {
      width: 100px;
      padding: 5px;
      border: solid 1px #30006d;
      color: #30006d;
      background-color: transparent;
    }
  }
  .header-upper {
    justify-content: center;
    display: flex;
  }

  .header-text {
    display: none;
  }
  .right-section {
    justify-content: flex-end;
  }
  .our-story-header {
    height: 190px;
    h1 {
      font-size: 40px;
    }
    .ourstory-img {
      height: 110px;
      width: 300px;
    }
  }
  .vision-img {
    height: 300px;
    width: 300px;
  }
  .contactus-img {
    height: 250px;
    width: 300px;
  }
  .tag-line {
    font-size: 25px;
  }
  .community-partner {
    .left {
      order: 2;
    }

    .right {
      order: 1;
    }
  }
  .people-card {
    padding: 0;
    .quote {
      i {
        color: $light-voilet !important;
        background-color: #ffffff;
        top: -10px;
      }
    }
  }
}
